import axios from "axios";

export class EstudiantesAPI {

    // DEFINICIÓN DE LA ESTRUCTURA DE UN ESTUDIANTE
    /**
     * @typedef { {
                            "codigo": string,
                            "correo": string,
                            "id": string,
                            "idCurso": string,
                            "nombreCurso": string,
                            "idSede": string,
                            "nombre": string,
                            "nombreSede": string,
                            "nombreInstituto":string,
                            "codigoAsignadoInstitucion": string,
                            "tiempoPromedioDeLectura": string,
                            "numeroTotalDeSesiones": string,
                            "numeroDeLibrosLeidos": string
                        }} Estudiante
 */
//---------------------------------------------------------------------------------
    // EMPIEZAN LOS SERVICIOS

            /**
         * Devuelve un arreglo con los estudiantes de toda la institución.
         * @returns {Promise<Estudiante[]>} - Arreglo de estudiantes
         */
    static async obtenerTodos() {
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const URL = 'estudiantes/obtenerListadoEstudiantes'
            const response = await axios({
                method: 'POST',
                url: URL,
            })
            const data = response.data.data;
            if (Array.isArray(data) && data.length <= 0) {
                return [];
            }
            const cursosTotales = [];

            Object.values(data).forEach((sede, i) => {
                const cursos = sede?.cursos;
                Object.values(cursos).forEach(curso => {
                    cursosTotales.push({
                        ...curso,
                        sede: {
                            id: Object.keys(data)[i],
                            nombre: sede?.nombre
                        }
                    })
                })
            })

            let estudiantes = [];
            cursosTotales.forEach(curso => {
                const est = curso?.estudiantes;
                Object.values(est).forEach(estudiante => {
                    estudiantes.push({
                        ...estudiante,
                        nombreCurso: curso?.nombre
                    })
                })
            })
            return estudiantes;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     * Devuelve un arreglo con los estudiantes de un curso.
     * @param {string} idCurso 
     * @returns {Promise<Estudiante[]>} - Arreglo de estudiantes
     */
    static async obtenerPorCurso(idCurso) {
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const URL = 'estudiantes/obtenerListadoEstudiantes'
            const response = await axios({
                method: 'POST',
                url: URL,
                data: { idCurso: idCurso }
            })
            const data = response.data.data;
            if (Array.isArray(data) && data.length <= 0) {
                return [];
            }
            const sede = Object.values(data)[0];
            const curso = sede ? sede?.cursos[idCurso] : { estudiantes: {} }
            let estudiantes = [];

            Object.values(curso?.estudiantes).map(estudiante => {
                estudiantes.push({ ...estudiante, nombreCurso: curso?.nombre || 'N/A' })
            })
            return estudiantes;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     * Devuelve un estudiante con base a su codigo de acceso
     * @param {string} codigoAcceso 
     * @returns {Promise<Estudiante> | Promise<undefined>} - Si el estudiante existe, devuelve el estudiante, sino, undefined.
     */
    static async obtenerPorCodigoAcceso(codigoAcceso) {
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const URL = 'estudiantes/obtenerListadoEstudiantes'
            const response = await axios({
                method: 'POST',
                url: URL,
                data: { idCurso: null, idSede: null }
            })
            const data = response.data.data;
            if (Array.isArray(data) && data.length <= 0) {
                return [];
            }
            const sedes = Object.values(data);
            const cursos = [];
            sedes.forEach(sede => {
                if (Boolean(sede?.cursos))
                    Object.values(sede?.cursos).forEach(curso => {
                        cursos.push(curso);
                    })
            })
            let estudiante = undefined;
            for (let i = 0; i < cursos.length; i++) {
                const curso = cursos[i];
                estudiante = curso?.estudiantes[codigoAcceso];
                if(Boolean(estudiante)){
                    estudiante = {...estudiante, nombreCurso:curso?.nombre}
                    break;
                }
            }
    
            return estudiante;
        } catch (error) {
            return Promise.reject(error);
        }
    }


    /**
       * Devuelve un estudiante con base a su id
       * @param {string} idEstudiante 
       * @returns {Promise<Estudiante> | Promise<undefined>} si el estudiante existe, devuelve el estudiante, sino, undefined.
       */
    static async obtenerPorId(idEstudiante) {
        try {
            const estudiantes = await EstudiantesAPI.obtenerTodos();
            const estudiante = estudiantes.find(estudiante => estudiante?.id === idEstudiante)
            return estudiante;
        } catch (error) {
            return Promise.reject(error);
        }
    }

}
