import PropTypes from 'prop-types';
import { useContext } from 'react';
import { AccesibilidadContext } from '../../../contexts/AccesibilidadContext';

/**
 * Componente que recibe una serie de props que permiten renderizar la tarjeta de libros leidos en la pantalla "Reportes gráficos de estudiantes" de estadísticas.
 * @returns TarjetaLibrosLeidos
 */
const TarjetaLibrosLeidos = (props) => {
  const { colorTarjeta, librosLeidos, textoTarjeta, alVerTodos, totalLibros } = props;
  const accesibilidad = useContext(AccesibilidadContext);

  const hexToRgbA = (hex) => {
    let nuevoColor = 0;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      nuevoColor = hex.substring(1).split('');
      if (nuevoColor.length === 3) {
        nuevoColor = [nuevoColor[0], nuevoColor[0], nuevoColor[1], nuevoColor[1], nuevoColor[2], nuevoColor[2]];
      }
      nuevoColor = '0x' + nuevoColor.join('');
      return 'rgba(' + [(nuevoColor >> 16) & 255, (nuevoColor >> 8) & 255, nuevoColor & 255].join(',') + ',0.5)';
    }
    throw new Error('Bad Hex');
  }

  return (
    <>
      <div 
        style={{ background: hexToRgbA(accesibilidad.temaActual === "clasico" ? colorTarjeta : '#000000') }} 
        className={`shadow-2 py-5 rounded-lg xl:w-451p ${accesibilidad.temaActual === "alto-contraste" && 'onbackground-var1--bordered'}`}
        role="grid"
        aria-colcount="2"
        aria-labelledby="descripcion"
      >
        <div className='px-5 md:px-8 mb-5' id="descripcion">
          <p className="r7-16-negro26" >{textoTarjeta.totalLibros}</p>
          <p className='r7-30 onbackground-var1--color'>
            <span className='icon-libro text-30 mr-3'></span>
            {totalLibros}  {totalLibros !== 1 ? textoTarjeta.libroPlural : textoTarjeta.libroSingular}
          </p>
        </div>

        <div role="rowgroup">
          <div className='alineado-verticalmente r7-16-negro26 px-5 md:px-8' role="row">
            <p className='w-9/12' role="columnheader" aria-colindex="1" id="libroTitulo">{textoTarjeta.libros}</p>
            <p className='w-3/12 text-center una-linea-texto' role="columnheader" aria-colindex="2" id="numeroLecturas">{textoTarjeta.lecturas}</p>
          </div>
        </div>
        <div role="rowgroup">
          {
            librosLeidos.map((libro, index) => {
              const infoLibro = [];
              if (index <= 4) {
                infoLibro.push(
                  <div key={`tarjeta-${index}`} className='py-2 border-b onsurface-var1--borderb-color onbackground-var1--color alineado-verticalmente px-5 md:px-8' role="row">
                    
                      <p className='w-9/12 r5-16 una-linea-texto' role="gridcell" aria-colindex="1" aria-describedby="libroTitulo">
                        <span aria-hidden={true}>{index + 1}.</span> {libro.nombreLibro || 'N/A'}
                      </p>
                      <p className='w-3/12 r5-16 text-center '  role="gridcell" aria-colindex="2" aria-describedby="numeroLecturas">
                        {libro.numeroDeLecturas || 0}
                      </p>
                    </div>
                  
                )
              }
              return infoLibro;
            })
          }
        </div>
        <div className='mt-5 mx-5 alineado-izquierda-center'>
          <button
            onClick={() => alVerTodos && alVerTodos()}
            className='boton-justo px-5 boton-amarillo boton_hover_naranja2 focusable-primary'>
            {textoTarjeta.botonVertodos}
          </button>
        </div>

      </div>
    </>
  )
}
export default TarjetaLibrosLeidos;

TarjetaLibrosLeidos.propTypes = {
  /**
   * String indica el color HEXADECIMAL de la tarjeta
   */
  colorTarjeta: PropTypes.string.isRequired,
  /**
   * Objecto que contiene la informacion de la tarjeta
   * @type[{nombre:string,
    lectura:number}] 
   */
  librosLeidos: PropTypes.array.isRequired,
  /**
   * objeto que contiene los textos de interfaz
   * @type{{
    totalLibros: string,
    libroPlural: string,
    libroSingular:string,
    libros: string,
    lecturas: string,
    botonVertodos: string }} 
   */
  textoTarjeta: PropTypes.object.isRequired,
  /**
   * callback utilizada para cuando se redirige a ver todos los resultados / pantalla libros mas leidos 
  * @type{Function(sede:sede):void} - callback
   */
  alVerTodos: PropTypes.func.isRequired,
  /**
   * Number, numero total de suma de los libros leidos
   */
   totalLibros: PropTypes.number.isRequired,
}