import axios from "axios";

export class LibrosLeidosProfesoresAPI {



    // DEFINICIÓN DE LOS TIPOS
    /**
     * @typedef {  
   {
  idLibro: string,
  tituloLibro: string,
  autor: string,
  editorial: string, 
  tiempoPromedioDeLectura: number,
  totalTiempoLectura: number,
  cantidadLecturas: number
}


  } LibroLeidoProfesor
  **/

    /**
     * @type {LibroLeidoProfesor}
     */
    static LibroLeidoPorDefecto = {
        idLibro:'N/A',
        tituloLibro:'N/A',
        autor:'N/A',
        editorial:'N/A',
        tiempoPromedioDeLectura:0,  
        cantidadLecturas:0,
        totalTiempoLectura:0  
    }



    //---------------------------------------------------------------------------------
    // EMPIEZAN LOS SERVICIOS

    /**
  * devuelve un arreglo con los libros leídos por los profesores en un rango de tiempo
  * @param {String} fechaInicio - la fecha de inicio de busqueda de información
  * @param {String} fechaFin - la fecha de fin  de busqueda de información
  * @returns {Promise<LibroLeidoProfesor[]>} LibroLeido[]
  */
    static async obtenerTodos(fechaInicio, fechaFin) {
        const body = {
            fechaInicio: fechaInicio || '',
            fechaFin: fechaFin || ''
        }
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method: 'POST',
                url: 'estadisticas/estadisticasLibrosLeidosProfesor',
                data: body
            })
            if (response.data.status != 1) {
                return new Error(response.data?.data?.info || 'Hubo un error, intente más tarde.');
            }

            const detalles = response.data?.data ||[ LibrosLeidosProfesoresAPI.LibroLeidoPorDefecto];
            return detalles;

        } catch (error) {
            return Promise.reject(error);
        }

    }

}

