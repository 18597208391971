import { useState, useRef, useContext } from "react";
/* import { useHistory } from "react-router-dom"; */
import MicroModal from "micromodal";
import ReactTooltip from 'react-tooltip';

/**container */
import FiltrosContainer from "../../containers/libros/FiltrosContainer";
import useIsMobile from "../../containers/helpers/hooks/useIsMobile";

/**componentes */
import MultiselectBar from "./MultiselectBar/MultiselectBar";
import SeleccionPaquetes from "./SeleccionPaquetes";
import ListadoLibros from "./ListadoLibros";
import AnalisisDeLaColeccion from "./analisisColeccion/AnalisisDeLaColeccion";
import ModalAdvertencia from "./ventanasModales/ModalAdvertencia";
import { useEffect } from "react";
import { Icon } from "@iconify/react";
import { MigasDePan, Miga } from "../globales/MigaDePan";
import { SeleccionLibrosInicialAPI } from "../../api/SeleccionLibrosInicialAPI";
import ModalVerificacion from "./ventanasModales/ModalVerificacion";
import SkeletonLibros from "../PerfilEstudiante/SkeletonsPerfilEstudiante/SkeletonLibros";
import { EmailAPI } from "../../api/EmailAPI";
import { useTrackedState } from "../../store";
import PropTypes from "prop-types";
import { AccesibilidadContext, TemasDisponibles } from "../../contexts/AccesibilidadContext";



/**
 * Componente que se encarga de realizar la lógica para gestionar el estado de los paquetes de libros, permitiendo agregar, remover libros a cada paquete y poder confirmarlos enviando el/los paquetes a Administración para ser aprobados
 */
const EscogiendoLibrosComponent = (props) => {
  const { cargandoPaquete, setModoFiltro, setLibrosFiltrados, librosFiltrados, setSchFilter, schFilter, AnalisisColeccion, textosInterfaz, paqueteParaSeleccionar, librosData, setLibrosData, selectFunctions } = props;

  const { miga_de_pan, libros, botones, filtros, modal_faltan_libros, modal_limite_libros, modal_validacion, multi_select } = textosInterfaz;
  const { elegir_libros, select } = libros;

  const state = useTrackedState();
  const usuario = state?.datosDeUsuario;
  const [activadorSelect, setActivadorSelect] = useState(false);
  const [activadorAnalisisColeccion, setAnalisisColeccion] = useState(false);
  const [librosAgregados, setLibrosAgregados] = useState([]);
  const [totalAgregados, setTotalAgregados] = useState(0);
  const [maximaCantidadLibros, setMaximaCantidadLibros] = useState(paqueteParaSeleccionar?.maxNumeroDeLibrosParaEscoger || 0);
  const [autorizado, setAutorizado] = useState(false);
  const [esperaAutorizacion, setEsperaAutorizacion] = useState(false);
  const [permitirMultiSeleccion, setPermitirMultiSeleccion] = useState(true);
  const [guardandoSeleccion, setGuardandoSeleccion] = useState(false);
  const acc = useContext(AccesibilidadContext);
 
  //Hooks personalizados
  const isMobile = useIsMobile();
  let inicioListaRef = useRef(null);

  const idModales = {
    faltanLibrosEnPaquetes: 'advertencia-faltan-libros-paquetes',
    faltanLibros: 'advertencia-faltan-libros-paquete-actual',
    limiteMaximo: 'advertencia-limite-maximo',
    confirmacionExitosa: 'paquete-confirmado',
  }


    /**
   * Esta función se usa para volver  al inicio de la lista de libros
   */
     const retornarAlPrincipio = () => {
      const top = window.scrollY - inicioListaRef.current.offsetTop;
      window.scrollTo({ top: top })
    }

 /**
  * Actualiza el paquete y su estado en el API
  */
  const actualizarLibrosAgregados = async (libros = [], confirmarSeleccion) => {
    return await SeleccionLibrosInicialAPI
      .escogerLibros(paqueteParaSeleccionar?.nombre || '', libros.map(libro => libro?.idLibro) || [], confirmarSeleccion)
      .catch(error => { console.log(error) })
  }

    /**
   * Actualiza el estado visual de los libros añadidos 
   */
     const cambiarEstadoSeleccion = (librosAEvaluar) => {
      const ids = librosAEvaluar?.map(libro => libro?.idLibro);
      setLibrosData({
        libros: [...librosData?.libros.map(libro => {
          if (ids?.includes(libro.idLibro)) {
            return { ...libro, elegido: 1 }
          }
          return { ...libro, elegido: 0 }
        })]
      })
    }
  


  // agrega al paquete libros  seleccionados desde el multiselector 
  const agregarLibrosSeleccionados = async (librosSeleccionados = []) => {
    const auxLibros = [...librosAgregados];
    const idsLibrosAgregados = librosAgregados?.map(libro => libro.idLibro);
    librosSeleccionados.forEach(libro => {
      if (!idsLibrosAgregados.includes(libro?.idLibro)) {
        auxLibros.push(libro)
      }
    })

    if (
      totalAgregados < maximaCantidadLibros &&
      auxLibros.length <= maximaCantidadLibros) {
      await actualizarLibrosAgregados(auxLibros, false)
        .then(respuesta => {
          setLibrosAgregados(auxLibros)
          cambiarEstadoSeleccion(auxLibros)
        })
        .catch(error => { console.log(error) })

    } else {
      selectFunctions?.deactivateMultiselect();
      MicroModal.show(idModales.limiteMaximo, {
        awaitCloseAnimation: true
      });
    }

  }


  // remueve del paquete libros seleccionados con el multiselector 
  const removerLibrosSeleccion = async (libros = []) => {
    let auxLibros = [...librosAgregados];
    const ids = librosAgregados?.map(libro => libro.idLibro);
    libros.forEach(libro => {
      if (ids.includes(libro?.idLibro)) {
        auxLibros = auxLibros.filter(lib => lib?.idLibro !== libro?.idLibro);
      }
    })
    await actualizarLibrosAgregados(auxLibros, false)
      .then(respuesta => {
        setLibrosAgregados(auxLibros)
        cambiarEstadoSeleccion(auxLibros)
      })
      .catch(error => { console.log(error) })
  }


  // envia el paquete como confirmado para ser autorizado.
  const confirmarSeleccion = async () => {
    if (totalAgregados === maximaCantidadLibros) {
      setGuardandoSeleccion(true);
      await actualizarLibrosAgregados(librosAgregados, true)
        .then(respuesta => {
          setLibrosAgregados(librosAgregados)
          cambiarEstadoSeleccion(librosAgregados)
          setGuardandoSeleccion(false);
          setEsperaAutorizacion(true);
          MicroModal.show(idModales.confirmacionExitosa, {
            awaitCloseAnimation: true
          });
        })
        .catch(error => { console.log(error) })

        await EmailAPI.autorizarPaquete(
          `${process.env.REACT_APP_EMAIL_AUTORIZACION_PAQUETE || 'equipo@makinaeditorial.com'}`, 
          `"correo:${usuario?.correo}, nombre:${usuario?.nombre}"`, 
          `"${paqueteParaSeleccionar?.nombre}"` )
        .catch(error => { console.log(error) })
    }
    else if (totalAgregados > maximaCantidadLibros) {
      MicroModal.show(idModales.limiteMaximo, {
        awaitCloseAnimation: true
      });
    }
    else {
      MicroModal.show(idModales.faltanLibros, {
        awaitCloseAnimation: true
      });
    }
  }

  const verificarPaquetesConfirmados = async ()=>{
    await SeleccionLibrosInicialAPI.paquetesDisponibles()
    .then(resultado=>{
      const noAutorizados = resultado?.filter(paquete=>paquete?.autorizado == 0);
      const faltanPaquetesPorConfirmar = noAutorizados.reduce((acc,curr)=>{
        if(Boolean(curr?.estadoSeleccion)==false){
          acc = true;
        }
        return acc;
      },false)
      if(faltanPaquetesPorConfirmar){
        MicroModal.show(idModales.faltanLibrosEnPaquetes, {
          awaitCloseAnimation: true
        });
      }
    })
    .catch(error=>{console.log(error)})
  }

  useEffect(() => {
    setTotalAgregados(librosAgregados.length || 0)
  }, [librosAgregados, paqueteParaSeleccionar])


  useEffect(() => {
    if (esperaAutorizacion && autorizado === false) {
      setPermitirMultiSeleccion(false)
    } else if (autorizado) {
      setPermitirMultiSeleccion(false)
    }
    else {
      setPermitirMultiSeleccion(true);
    }
  }, [esperaAutorizacion, autorizado])


  useEffect(() => {
    setAutorizado(Boolean(paqueteParaSeleccionar?.autorizado));
    const seleccionados = paqueteParaSeleccionar?.libros?.length || 0;
    const cantidadMaxima = paqueteParaSeleccionar?.maxNumeroDeLibrosParaEscoger || 0;
    setMaximaCantidadLibros(cantidadMaxima);
    setTotalAgregados(seleccionados);
    if (Boolean(paqueteParaSeleccionar?.estadoSeleccion)) {
      setEsperaAutorizacion(true);
    } else {
      setEsperaAutorizacion(false);
    }
  }, [paqueteParaSeleccionar])

  useEffect(() => {
    /**
* 
* @returns devuelve los libros que ya estan elegidos, se utiliza al momento de cargar los libros de cada paquete
*/
    const seleccionadosDataInicial = () => {
      return librosData?.libros.filter(libro => Boolean(libro?.elegido))
        ?.reduce((acc, curr) => {
          const ids = acc?.map(libro => libro?.idLibro);
          if (!ids.includes(curr?.idLibro)) {
            return [...acc, curr];
          } else {
            return acc;
          }
        }, [])
    }
    setLibrosAgregados(seleccionadosDataInicial())
  }, [librosData])

  useEffect(() => {
    selectFunctions?.deactivateMultiselect();
  }, [paqueteParaSeleccionar, librosData])

  return (
    <>
      <MultiselectBar {...props} eligiendoLibros={true} modoEscogerProps={{
        alAgregarASeleccion: (libros) => agregarLibrosSeleccionados(libros),
        alRemoverSeleccion: (libros) => removerLibrosSeleccion(libros),
      }} />

      <MigasDePan>
        <Miga texto={miga_de_pan.libros || 'Libros'} />
        <Miga texto={miga_de_pan?.escogiendo_libros || 'Escogiendo libros'} esRutaActual={true} />
      </MigasDePan>

      <div className="contenido">
        <div className="border-b-2 onsurface-var1--borderb-color xl:flex justify-between items-end relative">
          <h1 className="q7-28 onbackground-var1--color">{elegir_libros.titulo}</h1>
          <div className=" absolute w-full  red-inv-var2--bg  xl:relative xl:w-auto xl:rounded-md p-3 text-center text-20 mb-3">
            <p className="onred-inv-var1--color raleway-700">{`${libros.periodo_seleccion || 'El periodo de selección de libros finaliza el'} ${paqueteParaSeleccionar?.fechaLimiteParaEscogerLibros || 'N/A'}`}</p>
          </div>
        </div>

        <div className="lg:w-auto mt-32 xsm:mt-22 sm:mt-16  xl:mt-3">
          <p
            className="informacion-principal py-0 sm:inline">
            {elegir_libros.descripcion} 
            <span 
              className="ml-1 w-7 h-7 text-24 surface-var2--bg onsurface-var2--color cursor-pointer hover:bg-morado center inline-flex  rounded-full "
              data-tip={true}
              data-scroll-hide={true}
              data-for='tool-descripcion'>
              ?
              </span>
          </p>
          <ReactTooltip
            backgroundColor={acc.temaActual === TemasDisponibles.clasico?.id ? "#000" : "var(--background-var1)"}
            textColor={acc.temaActual === TemasDisponibles.clasico?.id ? "#FFF" : "var(--onbackground-var1)"}
            className="quicksand-400"
            id="tool-descripcion"
            place={isMobile ? "bottom" : "right"}
            event="mouseover mouseenter click"
            eventOff="mouseleave mouseout scroll mousewheel blur"
            effect={isMobile ? "solid" : "float"}
          >
            <p className="w-40 sm:w-72">{elegir_libros.tooltip}</p>
          </ReactTooltip>

        </div>



        <div className="md:alineado-verticalmente gap-8 pt-5">
          <div className="md:w-4/12 xl:w-4/12 mt-5 md:mt-0 ">
            <SeleccionPaquetes {...props} select={select} />
          </div>

          {
            cargandoPaquete == false &&
            <>
              <div className="md:w-4/12 xl:w-full  mt-6  md:mt-0 ">
                {
                  Boolean(esperaAutorizacion) === false &&
                  <button
                    disabled={totalAgregados > 0 ? false : true}
                    className={`focusable-red boton-amarillo hover:bg-morado hover:text-blanco  boton-justo  center }`}
                    onClick={() => {
                      confirmarSeleccion()
                    }}>
                    {multi_select?.libros?.confirmar_paquete || 'Confirmar paquete'}
                    {
                      guardandoSeleccion ? (
                        <Icon icon={'fa:spinner'} className={'m-1 text-22 animate-spin'} />
                      )
                        :
                        <Icon icon={'akar-icons:check'} className={'m-1 text-22'} />

                    }

                  </button>
                }
                {
                  (esperaAutorizacion) &&
                  <h2 className={`red-inv-var1--color font-raleway font-bold`}>
                    {`${libros?.espera_autorizacion || 'Este paquete esta en espera de autorización.'}`}</h2>
                }
                {
                  (autorizado) &&
                  <h2 className={`green-inv-var1--color font-raleway font-bold`}>
                    {`${libros?.esta_autorizado || 'Este paquete ya se encuentra autorizado.'}`}
                  </h2>
                }
              </div>
            </>
          }
        </div>


        {/* AE: Fue necesario crear un container para los filtros para evitar problemas de performance si ambos compartian los mismos estados */}


        <div className="lg:flex gap-4 border-t onsurface-var1--bordert-color mt-5 py-5 relative">
          <div className="columna-filtro z-60">
            {/* BOTON RESPONSIVE */}
            <button
              className="focusable-red w-full text-22 center py-2 boton-azul boton_hover_morado lg:hidden"
              onClick={() => { setActivadorSelect(!activadorSelect) }}
            >
              {botones.filtro}
              {<span className={`icon-select  text-24 ml-5 ${activadorSelect ? "" : "transform rotate-180"}`}></span>}
            </button>

            {/* FILTROS*/}
            <div className={`${isMobile ? activadorSelect ? 'h-350  p-3' : 'h-px' : 'p-3 w-64 h-screen-80'}  caja-filtros scroll background-var3--bg`}>

              <FiltrosContainer
                {...props}
                setLibrosFiltrados={setLibrosFiltrados}
                librosFiltrados={librosFiltrados}
                setSchFilter={setSchFilter}
                textosFiltros={filtros}
                botonesFiltros={botones}
                eligiendoLibros={true}
                alFiltrar={() => {
                  setActivadorSelect(false);
                  retornarAlPrincipio();
                  setModoFiltro({ activado: true })
                }}
                alLimpiarFiltros={() => {
                  setActivadorSelect(false);
                  retornarAlPrincipio();
                  setModoFiltro({ activado: false })
                }}
              />
            </div>
          </div>


          <div className="w-full mt-5 lg:mt-0">
            {cargandoPaquete == false &&
              <>
                {/* ANALISIS DE LA COLECCION */}
                <div className="columna-actual-seleccion primary-var5--bg px-3 py-5 max-h-screen-80 xl:max-h-screen-90 scroll" ref={inicioListaRef}>

                  <div className="sm:alineado-verticalmente sm:justify-between lg:block xl:alineado-verticalmente">
                    <h3 className="text-20  font-raleway font-bold primary-inv-var2--color">{`${totalAgregados}/${maximaCantidadLibros} ${libros?.seleccionados || 'seleccionados'}`}</h3>

                    <div className="xl:alineado-izquierda-center mt-3 sm:mt-0 lg:mt-3 xl:mt-0">
                      <button
                        onClick={() => { setAnalisisColeccion(!activadorAnalisisColeccion) }}
                        className={`focusable-red ${activadorAnalisisColeccion ? 'boton-rounded-top' : 'boton-justo boton-amarillo'} boton-linea-amarillo`}
                      >
                        {botones.analisis_coleccion}
                      </button>
                    </div>
                  </div>

                  <AnalisisDeLaColeccion
                    activador={activadorAnalisisColeccion}
                    analisisColeccion={AnalisisColeccion(librosAgregados)}
                    textosInterfaz={textosInterfaz}
                  />
                </div>
              </>
            }
            {/* LIBROS */}
            <ListadoLibros
              {...props}
              libros={librosFiltrados}
              schFilter={schFilter}
              analisisColeccion={AnalisisColeccion(librosFiltrados.filter(item => item.seleccionado))}
              eligiendoLibros={true}
              textosInterfaz={textosInterfaz}
              permitirMultiSeleccion={permitirMultiSeleccion}
            />
          </div>
        </div>
      </div>
      {/* MODALES */}
      <ModalAdvertencia
        nombreDelModal={idModales.limiteMaximo}
        titulo={modal_limite_libros?.titulo || '¡Limite de libros!'}
        descripcion={`
        ${modal_limite_libros?.descripcion1 || 'Tienes permitido añádir hasta' } 
        ${maximaCantidadLibros} 
        ${modal_limite_libros?.descripcion2 || 'libros al paquete' } '${paqueteParaSeleccionar?.nombre}'
        ${modal_limite_libros?.descripcion3 || '. Al parecer haz alcanzado el limite o estas intentado agregar más libros de lo permitido.' }`}
      />
      <ModalAdvertencia
        nombreDelModal={idModales.faltanLibros}
        titulo={modal_faltan_libros?.titulo || 'Aún te faltan libros por escoger'}
        descripcion={
          `${modal_faltan_libros?.descripcion1 || 'Has añadido'} ${totalAgregados} ${modal_faltan_libros?.de || 'de'} ${maximaCantidadLibros} ${modal_faltan_libros?.descripcion2 || 'libros. Aún tienes libros por añadir a este paquete.'}`
        }
      />
      <ModalVerificacion
        nombreDelModal={idModales.confirmacionExitosa}
        imagen={'/images/eyex-creado.png'}
        titulo={modal_validacion?.paquete_confirmado?.titulo || 'Tu selección se envió a MakeMake'
        }
        descripcion={
          `${modal_validacion?.paquete_confirmado?.descripcion ||
          'Nuestro equipo se comunicará contigo para continuar tu proceso de configuración.'}`
        }
        tituloBotonConfirmacion={modal_validacion?.confirmar || 'Entendido'}
        alConfirmar={() =>{ 
          MicroModal.close(idModales.confirmacionExitosa);
          verificarPaquetesConfirmados();
        }}
      />

      <ModalVerificacion
        nombreDelModal={idModales.faltanLibrosEnPaquetes}
        imagen={'/images/eyex-pregunta.png'}
        titulo={modal_validacion?.faltan_paquetes?.titulo || 'Tu selección se envió a MakeMake'
        }
        descripcion={
          `${modal_validacion?.faltan_paquetes?.descripcion ||
          'Nuestro equipo se comunicará contigo para continuar tu proceso de configuración.'}`
        }
        tituloBotonConfirmacion={modal_validacion?.confirmar || 'Entendido'}
        alConfirmar={() => MicroModal.close(idModales.faltanLibrosEnPaquetes)}
      />

    </>
  )
}
export default EscogiendoLibrosComponent;

EscogiendoLibrosComponent.propTypes = {
  /**
   * Propiedad boolean que indica si el componente está cargando la información del paquete.
   */
  cargandoPaquete:PropTypes.bool, 
  /**
   * función que actualiza el estado del modo filtro de búsqueda, recibe un parametro booleano que indica si está en modo filtro o no.
   */
  setModoFiltro:PropTypes.func, 
  /**
   * función que actualiza los libros filtrados por el filtro de búsqueda, recibe un parametro array que contiene los libros filtrados
   */
  setLibrosFiltrados:PropTypes.func, 
  /**
   * Arreglo de los libros filtrados por el filtro de búsqueda.
   */
  librosFiltrados:PropTypes.arrayOf(PropTypes.object), 
  /**
   * función que actualiza el filtro de busqueda por titulo.
   */
  setSchFilter:PropTypes.func, 
    /**
   * texto actual del filtro de busqueda por titulo.
   */
  schFilter:PropTypes.string, 
  /**
   * Función que retorna los filtros disponibles para la busqueda de libros.
   */
  AnalisisColeccion:PropTypes.func, 
  /**
   * Objeto con los textos traducidos de la interfaz del componente.
   */
  textosInterfaz:PropTypes.object, 
  /**
   * El paquete actual que se está configurando.
   */
  paqueteParaSeleccionar:PropTypes.shape({
    autorizado:PropTypes.bool,
    estadoSeleccion:PropTypes.bool,
    fechaLimiteParaEscogerLibros:PropTypes.string,
    libros:PropTypes.arrayOf(PropTypes.object),
    maxNumeroDeLibrosParaEscoger:PropTypes.number,
    nombre:PropTypes.string,
  }), 
  /**
   * Objeto con todos los libros disponibles y su correspndiente estado con base al paquete actual.
   */
  librosData:PropTypes.shape({
    libros:PropTypes.arrayOf(PropTypes.object),
  }), 
  /**
   * Función que actualiza los libros de 'librosData'
   */
  setLibrosData:PropTypes.func, 
  /**
   * Objeto con funciones que gestionan el estado del multiselector y los libros.
   */
  selectFunctions:PropTypes.object 
}