import axios from "axios";

export class LibrosLeidosAPI {



    // DEFINICIÓN DE LOS TIPOS
    /**
     * @typedef {  
   {
  idLibro: string,
  tituloLibro: string,
  autor: string,
  editorial: string, //mins
  tiempoPromedioDeLectura: number,
  cantidadLecturas:number,
  totalTiempoLectura:number
}


  } LibroLeido
  **/

    /**
     * @type {LibroLeido}
     */
    static LibroLeidoPorDefecto = {
        idLibro:'N/A',
        tituloLibro:'N/A',
        autor:'N/A',
        editorial:'N/A',
        tiempoPromedioDeLectura:0
    }



    //---------------------------------------------------------------------------------
    // EMPIEZAN LOS SERVICIOS

    /**
  * Devuelve un arreglo de los libros leídos y sus estadísticas con base a una sede.
  * @param {String} idSede - id de la sede
  * @param {String} fechaInicio - la fecha de inicio de busqueda de información
  * @param {String} fechaFin - la fecha de fin  de busqueda de información
  * @returns {Promise<LibroLeido[]>} LibroLeido[]
  */
    static async obtenerPorSede(idSede, fechaInicio, fechaFin) {
        const body = {
            idSede: idSede,
            fechaInicio: fechaInicio || '',
            fechaFin: fechaFin || ''
        }
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method: 'POST',
                url: 'estadisticas/estadisticasLibrosLeidos',
                data: body
            })
            if (response.data.status != 1) {
                return new Error(response.data?.data?.info || 'Hubo un error, intente más tarde.');
            }

            const detalles = response.data?.data ||[ LibrosLeidosAPI.LibroLeidoPorDefecto];
            return detalles;

        } catch (error) {
            return Promise.reject(error);
        }

    }

    /**
  *  Devuelve un arreglo de los libros leídos y sus estadísticas con base a un curso.
  * @param {String} idCurso - id del curso
  * @param {String} fechaInicio - la fecha de inicio de busqueda de información
  * @param {String} fechaFin - la fecha de fin  de busqueda de información
  * @returns {Promise<LibroLeido[]>} LibroLeido[]
  */
     static async obtenerPorCurso(idCurso, fechaInicio, fechaFin) {
        const body = {
            idCurso: idCurso || '',
            fechaInicio: fechaInicio || '',
            fechaFin: fechaFin || ''
        }
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method: 'POST',
                url: 'estadisticas/estadisticasLibrosLeidos',
                data: body
            })
            if (response.data.status != 1) {
                return new Error(response.data?.data?.info || 'Hubo un error, intente más tarde.');
            }

            const detalles = response.data?.data || [LibrosLeidosAPI.LibroLeidoPorDefecto];
            return detalles;

        } catch (error) {
            return Promise.reject(error);
        }

    }

}

