import { useState, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { VisorAPI } from '../../api/VisorAPI';

//Hooks y funciones personalizadas
import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';

//Componentes
import Error_404 from '../Error_404';
import VisorComponent from '../../components/visor/visorComponent';

/**
 * Este container se encarga de pasar la información del libro que se va a mostrar y los textos de la interfaz de el visor dependiendo del idioma del usuario al componente VisorComponent
 * @returns {JSX.Element} VisorComponent retorna el componente VisorComponent que se encarga de renderizar los diferentes tipos de libros.
 */
const Visor = () => {

  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('visor');

  const [isLoading, setIsloading] = useState(true);
  const [datosLibro, setDatosLibro] = useState('');
  const location = useLocation();

  useEffect(() => {
    if (location) {
      let path = location.pathname;
      let id = path.substring(path.lastIndexOf('/') + 1)
      const cargarDatosVisor = async () => {
        await VisorAPI
          .visor(id)
          .then(resultado => {
            setDatosLibro(resultado);
            setIsloading(false);
          })
          .catch(error => console.log(error))
      }
      cargarDatosVisor();
    }

  }, [location.pathname]);

  return (
    <>
      <Switch>
        <Route exact path="/visor/:idLibro">
          {
            isReady &&
            <VisorComponent
              datosLibro={datosLibro}
              isLoading={isLoading}
              traducciones={textosInterfaz}
              pathAnterior={location?.state?.pathAnterior || ''}
            />
          }
        </Route>
        <Route component={Error_404} />
      </Switch>
    </>
  )

}

export default Visor;