import { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";

/**componente */
import AsignarAClub from "./AsignarAClub";
import InformacionBasica from "./InformacionBasica";
import Detalles from "./Detalles";
import Modal from "../../globales/modales/Modal";
import MicroModal from "micromodal";
import { LibrosAPI } from "../../../api/LibrosAPI";
import { AccionesLibroAPI } from "../../../api/AccionesLibroAPI";
import { Recomendar } from "./Recomendados";
import { SeleccionLibrosInicialAPI } from "../../../api/SeleccionLibrosInicialAPI";
import ModalAdvertencia from "../ventanasModales/ModalAdvertencia";
import { Libro } from "../../globales/libro/LibroComponent";
import Icon from "react-multi-date-picker/components/icon";
import PropTypes from "prop-types";


const URL_IMG_POR_DEFECTO = '/images/no-imagen.png';

/**
 * Componente que se encarga de renderizar la información de un libro, 
 * dentro de este componente se encuentran los componentes para asignar libros a clubs y recomendar a estudiantes.
 */
const LibroFicha = (props) => {
  const { escogiendoLibrosProps, informacionLibro, eligiendoLibros, textosInterfaz } = props;
  const { miga_de_pan, ficha, botones, modal_compartir, modal_limite_libros } = textosInterfaz;
  const { info_basica, mas_detalles } = ficha;

  const { portada, title, author, description, descriptionENG, editorial, nivelUsuarioLector, subject, genero, idLibro, contributor, edad, tiempoLectura, curso, idActividad, dificultadLectora } = informacionLibro;

  const [modoInfo, setModoInfo] = useState('basica');
  const [modalCompartir, setModalCompartir] = useState(false);
  const [libro, setLibro] = useState(undefined);
  const [esFavorito, setEsFavorito] = useState(false);
  const history = useHistory();
  const [libroAgregado, setLibroAgregado] = useState(false);
  const [totalAgregados, setTotalAgregados] = useState(0);
  const [estadoPortada, setEstadoPortada] = useState({
    enlace:URL_IMG_POR_DEFECTO
  })


  const urlDireccion = useRef(null);

  const idModales = {
    limiteMaximo: 'advertencia-limite-maximo-libros-ficha',
    modalCompartir: 'modal-compartir-ficha-libro',
  }

  const actualizarTotal = async () => {
    if (eligiendoLibros) {
      await SeleccionLibrosInicialAPI.obtenerPaquete(escogiendoLibrosProps?.paqueteActual?.nombre)
        .then(resultado => {
          setTotalAgregados(resultado?.libros?.length)
        })
    }
  }

  const agregarRemoverAFavoritos = async (libro, favorito) => {
    setEsFavorito(favorito);
    await AccionesLibroAPI
      .agregarRemoverAFavoritos([libro?.idLibro], favorito)
      .catch(error => console.log(error))
  }

  const mostrarLibro = (idLibro) => {
    history.push({ pathname: `/visor/${idLibro}`, state: { idLibro: idLibro, pathAnterior: window.location.pathname } });
  }

  /**
 * Actualiza el paquete y su estado en el API
 */
  const actualizarLibrosAgregados = async (librosIds = [], confirmarSeleccion) => {
    return await SeleccionLibrosInicialAPI
      .escogerLibros(escogiendoLibrosProps?.paqueteActual?.nombre || '', librosIds || [], confirmarSeleccion)
      .catch(error => { console.log(error) })
  }


  const EscogiendoLibros = {
    agregarLibro: async () => {
      const paqueteActual = escogiendoLibrosProps?.paqueteActual;
      if (paqueteActual?.libros?.length < paqueteActual?.maxNumeroDeLibrosParaEscoger) {
        const ids = paqueteActual?.libros?.map(libro => libro?.idLibro);
        const actualizados = [...ids, informacionLibro?.idLibro]
        await actualizarLibrosAgregados(actualizados, false)
          .then(resultado => {
            setLibroAgregado(true);
            setTotalAgregados(totalAgregados + 1)
          })
          .catch(error => console.log(error))
      } else {
        MicroModal.show(idModales.limiteMaximo, {
          awaitCloseAnimation: true
        })
      }
    },
    removerLibro: async () => {
      const paqueteActual = escogiendoLibrosProps?.paqueteActual;
      const ids = paqueteActual?.libros?.map(libro => libro?.idLibro);
      const actualizados = [...ids].filter(id => id !== informacionLibro?.idLibro);
      await actualizarLibrosAgregados(actualizados, false)
        .then(resultado => {
          setLibroAgregado(false);
          setTotalAgregados(totalAgregados - 1)
        })
        .catch(error => console.log(error))
    }
  }


  useEffect(() => {
    setLibroAgregado(Boolean(informacionLibro?.elegido))
  }, [informacionLibro])

  useEffect(() => {
    actualizarTotal();
  }, [escogiendoLibrosProps?.paqueteActual])

  useEffect(() => {
    if (modalCompartir) {
      MicroModal.show(idModales.modalCompartir, {
        awaitCloseAnimation: true
      });
    }
  }, [modalCompartir])

  useEffect(() => {
    const cargarInfoLibro = async () => {
      await LibrosAPI.buscarPorId(informacionLibro?.idLibro || 'N/A')
        .then(resultado => {
          setLibro(resultado)
        })
        .catch(error => console.log(error))
    }
    cargarInfoLibro();
  }, [])

  useEffect(() => {
    setEsFavorito(libro?.favorito)
  }, [libro])

  useState(() => {
      setEstadoPortada({
        enlace:informacionLibro?.portada
      })
  }, [informacionLibro])



  return (
    <>

      <div className="ruta">
        <p className="font-medium">
          <Link to='/libros' className="focusable-primary mr-2">{miga_de_pan.libros}</Link>  &gt;
          <span className="ml-2 font-bold ">{miga_de_pan.detalle_de} {title}</span>
        </p>
      </div>

      <div className="contenido relative">
        <h1 className="titulos-principales mb-5">{ficha.titulo}</h1>
        <Link to="/libros" className="focusable-primary w-28 lg:center boton-justo boton-amarillo boton_hover_morado py-2 px-5">
          <span className="icon-atras mr-3"></span>
          {botones.volver}
        </Link>
        {
          (eligiendoLibros && Boolean(escogiendoLibrosProps?.paqueteActual?.estadoSeleccion) === false) &&
          <div className="w-full z-60 " style={{ position: 'sticky', top: 62, left: 0 }}>
            <div className="rounded-md w-full shadow-1 flex flex-col sm:flex-row center sm:justify-between items-center p-2 my-3 primary-var5--bg primary-inv-var5--bordered" >
              <p className="font-bold font-raleway onbackground-var1--color">
                {escogiendoLibrosProps?.paqueteActual?.nombre || 'N/A'}
                <span className="primary-inv-var2--color">
                  {` (${totalAgregados}/${escogiendoLibrosProps?.paqueteActual?.maxNumeroDeLibrosParaEscoger})`}
                </span>
              </p>
              <div className="flex center">
                <button
                  disabled={libroAgregado}
                  className="focusable-red boton-morado boton-justo hover:bg-morado-oscuro transicion-150 mr-2"
                  onClick={() => EscogiendoLibros.agregarLibro()}>
                  {libroAgregado ?
                    `${ficha?.escogiendoLibros?.agregado || 'Añadido'}` :
                    `${ficha?.escogiendoLibros?.agregar || 'Añadir'}`
                  }
                </button>
                <button
                  disabled={libroAgregado ? false : true}
                  className="focusable-red boton-morado boton-justo hover:bg-morado-oscuro transicion-150"
                  onClick={() => EscogiendoLibros.removerLibro()}>
                  {`${ficha?.escogiendoLibros?.quitar || 'Quitar'}`}
                </button>
              </div>
            </div>
          </div>

        }
        <div className="xl:flex gap-16 mt-5">

          <div className=" xl:w-3/12">
              <img
                src={estadoPortada?.enlace}
                alt={`Portada del libro`}
                onError={(e) => {
                  setEstadoPortada({
                    enlace:URL_IMG_POR_DEFECTO
                  })
                }}
                className={`w-8/12  sm:w-6/12 md:w-4/12  mx-auto xl:w-full mt-10 shadow-1 ${eligiendoLibros && 'mb-6'} `}
              />

            {
              !eligiendoLibros &&
              <>
                <button
                  className="focusable-red w-full center py-7 lg:py-4 q7-16-negro26 border-b border-gris-linea"
                  onClick={() => {
                    agregarRemoverAFavoritos(libro, !esFavorito)
                  }}
                >
                  <span className={`icon-corazon text-26 mr-2 ${esFavorito ? 'red-inv-var2--color' : ' favorito'}`}></span>
                  {ficha.favorito}
                </button>

                <Recomendar.General
                  idLibro={informacionLibro?.idLibro || ''}
                />

                <AsignarAClub
                  {...props}
                  idLibro={informacionLibro?.idLibro || ''}
                  textosInterfaz={textosInterfaz}
                />

              </>
            }
          </div>

          <div className="xl:w-9/12">

            <div className="flex gap-2 md:gap-4">
              <button
                className={`focusable-red ${modoInfo === 'basica' ? 'boton-morado' : 'boton-blanco-ficha'} w-6/12  md:w-auto rounded-t-lg quicksand-700  md:px-8 py-1 text-20`}
                onClick={() => { setModoInfo('basica') }}
              >
                {info_basica.titulo}
              </button>
              <button
                className={`focusable-red ${modoInfo === 'detalles' ? 'boton-morado' : 'boton-blanco-ficha'} w-6/12 md:w-auto  rounded-t-lg quicksand-700 md:px-8 py-1 text-20`}
                onClick={() => { setModoInfo('detalles') }}
              >
                {mas_detalles.titulo}
              </button>
            </div>

            <div className="background-var3--bg border-2 onsurface-var2--border-color min-h-600  relative">
              {modoInfo === 'basica' ?
                <InformacionBasica
                  title={title}
                  author={author}
                  publisher={editorial}
                  nivelUsuarioLector={nivelUsuarioLector}
                  subject={subject}
                  genero={genero}
                  description={description}
                  descriptionENG={descriptionENG}
                  contributor={contributor}
                  idLibro={idLibro}
                  actividad={
                    (Boolean(idActividad) || 
                    (Array.isArray(idActividad) && idActividad.length > 0)) ||
                    Boolean(informacionLibro?.actividad) ||
                    (Array.isArray(informacionLibro?.actividades) && informacionLibro?.actividades?.length > 0)? 
                    true 
                    : 
                    false
                  }
                  textosInterfaz={textosInterfaz}
                />
                :

                <>
                  <div className="background-var3--bg grid grid-cols-12 gap-4">

                    <div className="col-span-12 xl:col-span-9 p-10">
                      <Detalles informacionLibro={informacionLibro} textosInterfaz={textosInterfaz} />
                    </div>

                    <div className="col-span-12 xl:col-span-3  gap-4 xl:block mx-10 xl:m-0 xl:my-10 xl:mr-10">

                      <div className="md:w-6/12 xl:w-full orange-inv-var1--bg rounded-xl p-3 center mb-5">
                        <div className="text-center r7-18-blanco leading-5">
                          <p>{mas_detalles.edad.titulo}</p>
                          <p>{mas_detalles.edad.desde} {edad} {mas_detalles.edad.anios}</p>
                        </div>
                      </div>

                      <div className="md:w-6/12 xl:w-full  boton-ficha center text-center lg:block leading-5 mb-5">
                        <span className="icon-tiempo primary-inv-var2--color text-40 mr-3 lg:mr-0"></span>
                        <div>
                          <p>{mas_detalles.tiempo_lectura}</p>
                          <p className="primary-inv-var2--color">{tiempoLectura} min</p>
                        </div>
                      </div>

                      <div className="md:w-6/12 xl:w-full boton-ficha text-center mb-5 center">
                        <div className="text-center leading-5">
                          <p>{mas_detalles.curso}</p>
                          <p className="primary-inv-var2--color">{curso}</p>
                        </div>
                      </div>
                      {!!dificultadLectora &&
                        <div className="md:w-6/12 xl:w-full boton-ficha center text-center lg:block mb-5">
                          <p className="q7-morado text-40 leading-7 mb-2 lg:text-80 lg:leading-10 lg:mb-5 mr-3 lg:mr-0">{dificultadLectora}</p>
                          <p>{mas_detalles.gr_level}</p>
                        </div>
                      }

                    </div>
                  </div>
                </>

              }

              <div className="md:alineado-izquierda-center m-10">
                {
                  !eligiendoLibros &&
                  <button
                    className="focusable-red w-full md:w-auto boton-justo py-2 px-8 boton-morado boton_hover_amarillo text-18 mt-3 md:mt-0 md:ml-5"
                    onClick={() => {
                      if (modalCompartir) {
                        MicroModal.show(idModales.modalCompartir, {
                          awaitCloseAnimation: true
                        })
                      } else {
                        setModalCompartir(true);
                      }
                    }}
                  >
                    {botones.compartir}
                    <span className="icon-compartir-small ml-3"></span>
                  </button>
                }

                <button
                  className="focusable-red w-full md:w-auto boton-justo py-2 px-8 boton-amarillo boton_hover_morado text-18 mt-3 md:mt-0 md:ml-5"
                  onClick={() => { mostrarLibro(idLibro) }}
                >
                  {botones.leer}
                </button>
              </div>
            </div>

          </div>

        </div>
      </div>

      <ModalAdvertencia
        nombreDelModal={idModales.limiteMaximo}
        titulo={modal_limite_libros?.titulo || '¡Limite de libros!'}
        descripcion={`
        ${modal_limite_libros?.descripcion1 || 'Tienes permitido añádir hasta'} 
        ${escogiendoLibrosProps?.paqueteActual?.maxNumeroDeLibrosParaEscoger} 
        ${modal_limite_libros?.descripcion2 || 'libros al paquete'} '${escogiendoLibrosProps?.paqueteActual?.nombre}'
        ${modal_limite_libros?.descripcion3 || '. Al parecer haz alcanzado el limite o estas intentado agregar más libros de lo permitido.'}`}
      />

      {/* Modal de compartir */}
      {modalCompartir &&
        <>
          <Modal nombreModal={idModales.modalCompartir}>
            <div className="pb-3">
              <h1 className="quicksand-700 onbackground-var2--color text-30 text-center ">{modal_compartir.titulo}</h1>

              <p className="text-center r7-16-negro26 mt-5 ">{modal_compartir.descripcion}</p>

              <div className="mt-5 relative">
                <input
                  className="w-full shadow-1"
                  disabled={true}
                  readOnly
                  ref={urlDireccion}
                  value={`${window.location.host}/visor/${idLibro}`}
                />
                <span className="icon-copiar cursor-pointer absolute top-2  right-2 surface-var2--color hover:blue-var1--color"

                  onClick={() => { navigator.clipboard.writeText(urlDireccion.current.value) }}>

                </span>
              </div>
            </div>
          </Modal>
        </>
      }
    </>
  )
}
export default LibroFicha;


LibroFicha.propTypes = { 
  /**
   * Objeto con todas las propiedades del libro que se quiere mostrar.
   */ 
  informacionLibro: PropTypes.object, 
  /**
   *  Objeto que se pasa si el usuario esta en el modo de escoger libros, las propiedades son el paquete actual y los paquetes disponibles.
   */
  escogiendoLibrosProps: PropTypes.shape({
      paqueteActual: PropTypes.object,
      paquetesDisponibles: PropTypes.array
  }), 
  /**
   * Propiedad booleana que indica si el usuario esta en el modo de escoger libros.
   */
  eligiendoLibros: PropTypes.bool,
  /**
   * objeto con los textos traducidos que debe renderizar el componente.
   */
  textosInterfaz: PropTypes.object,
}