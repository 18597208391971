import axios from "axios";

export class DetalleEstudianteAPI {

 

  // DEFINICIÓN DE LOS TIPOS
  /**
   * @typedef {{
  idEstudiante: string,
  nombreCompleto: string,
  numeroDeMedallasGanadas: number,
  numeroDeLibrosConsultados: number,
  numeroDehorasLeidas: number,
  sesionesDeLectura: [{
    fecha: 'yyy-mm-dd',
    tiempoPromedioLectura: number, //mins
    tiempoPromedioEnPlataforma: number, //mins
    librosLeidos: number,
    numeroSesiones:number
  }],  
  librosLeidos: [{
    titulo: string,
    autor: string,
    editorial:string,
    fechaLectura:string,
    tiempoPromedioDeLectura: number//mins,
    tiempoTotalLectura:number //mins
  }]
}
} DetalleEstudiante
**/

/**
 * @type {DetalleEstudiante}
 */
static detalleEstudiantePorDefecto = {
    idEstudiante:'',
    nombreCompleto:'N/A',
    numeroDeLibrosConsultados:0,
    numeroDeMedallasGanadas:0,
    numeroDehorasLeidas:0,
    librosLeidos:[],
    sesionesDeLectura:[]
}



  //---------------------------------------------------------------------------------
  // EMPIEZAN LOS SERVICIOS

  /**
* devuelve las estadisticas de un estudiante con base a el id y rango de fechas 
* @param {String} idEstudiante - el id del estudiante que se quiere buscar
* @param {String} fechaInicio - la fecha de inicio de busqueda de información
* @param {String} fechaFin - la fecha de fin  de busqueda de información
* @returns {Promise<DetalleEstudiante>} DetalleEstudiante
*/
  static async obtenerDetalles(idEstudiante, fechaInicio, fechaFin, abortSignal) {
    const body = {
        idEstudiante:idEstudiante || '',
        fechaInicio:fechaInicio || '', 
        fechaFin:fechaFin || ''
    }
    try {
      axios.defaults.baseURL = process.env.REACT_APP_API_URL;
      axios.defaults.withCredentials = true;
      const response = await axios({
        method: 'POST',
        url: 'estadisticas/estadisticasDetalleEstudiante',
        data: body,
        signal:abortSignal
      })
      if(response.data.status != 1 ){
          return new Error(response.data?.data?.info || 'Hubo un error, intente más tarde.');
      }
      /**
       * @type{DetalleEstudiante}
       */
      const detalles = response.data?.data || DetalleEstudianteAPI.detalleEstudiantePorDefecto;
      return detalles;
      
    } catch (error) {
      return Promise.reject(error);
    }

  }

}