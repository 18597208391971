import { useState, useEffect } from "react";

/**componente */
import { AccionesLibroAPI } from "../../../../api/AccionesLibroAPI";
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";
import { useTrackedState } from "../../../../store";
import { ProfesoresAPI } from '../../../../api/ProfesoresAPI';
import { CursosAPI } from '../../../../api/CursosAPI';
import { EstudiantesAPI } from "../../../../api/EstudiantesAPI";
import { Recomendar } from ".";
import MicroModal from "micromodal";
import PropTypes from "prop-types";

/**
 Este componente tiene la lógica principal para recomendar un libro a uno o varios estudiantes de los cursos existentes en el instituto, se encarga de mostrar los cursos existentes,  los cursos ya recomendados, los estudiantes a quienes se recomendó y permite editar cada curso de manera independiente.
 */
export const General = (props) => {

  const { idLibro } = props;
  const { isReady, textosSubSecciones: traducciones } = useObtenerSubsecciones('libros');
  const state = useTrackedState();
  const [guardandoCambios, setGuardandoCambios] = useState(false);
  const [modalCursosVisible, setModalCursosVisible] = useState(false);
  const [modalCursoEspecificoVisible, setModalCursoEspecificoVisible] = useState(false);
  const [cursos, setCursos] = useState([]);
  const [asignados, setAsignados] = useState([]);
  const [asignadoActual, setAsignadoActual] = useState(undefined);
  const idModalCursos = 'modal-recomendar-cursos-ficha-libro';
  const idModalCursoEspecifico = 'modal-recomendar-curso-especifico-ficha-libro';


  const cursosOrdenados = () => {
    let ordenados = [...cursos].sort((a, b) => {
      if (a?.nombre < b?.nombre) {
        return -1;
      }
      if (a?.nombre > b?.nombre) {
        return 1;
      }
      return 0;
    })
    return ordenados;
  }




  const removerAsignacion = async (asignacion) => {
    await AccionesLibroAPI.multiplesAcciones({
      accion: 'Ocultar',
      codigoEstudiantes: asignacion?.estudiantesSeleccionados?.map(est => est?.codigo),
      idLibros: [idLibro]
    })
      .then(resultado => {

        setAsignados([...asignados.filter(asignado => asignado?.curso?.id !== asignacion?.curso?.id)])
        let auxCursos = cursos;
        const ids = cursos.map(curso => curso?.id);
        asignados.forEach(asignado => {
          if (ids.includes(asignado?.curso?.id) == false) {
            auxCursos.push(asignado?.curso);
          }
        })
        setCursos(auxCursos);
      })
      .catch(error => console.log(error))

  }


  const gestionarAsignados = (asignacion) => {
    const hayEstudiantesSeleccionados = Array.isArray(asignacion?.estudiantesSeleccionados) && asignacion?.estudiantesSeleccionados.length > 0;
    if (hayEstudiantesSeleccionados) {
      const aux = asignados.filter(asignado => asignado?.curso?.id !== asignacion?.curso?.id);
      const asignaciones = [...aux, asignacion]
      setAsignados(asignaciones);
    }else{
      setAsignados([
        ...
        asignados.filter(asignado => asignado?.curso?.id !== asignacion?.curso?.id)
      ]);
      let auxCursos = cursos;
      const ids = cursos.map(curso => curso?.id);
      asignados.forEach(asignado => {
        if (ids.includes(asignado?.curso?.id) == false) {
          auxCursos.push(asignado?.curso);
        }
      })
      setCursos(auxCursos);
    }
  }

  useEffect(() => {
    let auxCursos = cursos;
    const ids = auxCursos.map(curso => curso?.id);
    const idsAsignados = asignados.map(asignacion => asignacion?.curso?.id);
    asignados.forEach(asignado => {
      if (ids.includes(asignado?.curso?.id)) {
        auxCursos = auxCursos.filter(curso => curso?.id !== asignado?.curso?.id);
      }
    })
    setCursos(auxCursos);
  }, [asignados])


  useEffect(() => {
    const cargarAsignados = async (cursosDisponibles) => {
      let aux = [];
      Promise.all(
        cursosDisponibles.map(async (curso, i) => {
          let estudiantesRecomendados = [];
          await EstudiantesAPI.obtenerPorCurso(curso?.id)
            .then(resultado => {
              resultado.forEach(estudiante => {
                const recomendados = estudiante?.escogidosPorElProfesor;
                if (Array.isArray(recomendados) && recomendados.length > 0) {
                  const recomendadosIDs = recomendados.map(libro => libro?.idLibro);
                  if (recomendadosIDs.includes(idLibro)) {
                    estudiantesRecomendados.push(estudiante);
                  }
                }
              })
            })

          if (estudiantesRecomendados.length > 0) {
            aux.push(
              {
                curso: curso,
                estudiantesSeleccionados: estudiantesRecomendados
              }
            )

          }
        })
      )
        .then(resultado => {
          setAsignados([...aux])
        })

    }

    const cargarCursos = async () => {
      if (state?.planActivo?.tipoUsuario === 'rector') {
        await CursosAPI
          .obtenerTodos()
          .then(resultado => {
            //se modifica el nombre de los cursos a 'sede-curso'
            const cursosTransformados = resultado.map(curso => {
              return { ...curso, nombre: `${curso?.sedeNombre} - ${curso?.nombre}` }
            })
            setCursos(cursosTransformados)
            cargarAsignados(cursosTransformados)
          })
      }
      if (state?.planActivo?.tipoUsuario === 'profesor') {
        await ProfesoresAPI
          .obtenerPorCorreo(state?.datosDeUsuario?.correo)
          .then(resultado => {
            //se modifica el nombre de los cursos a 'sede-curso'
            const cursosProfesor = Array.isArray(resultado?.cursos) ? resultado?.cursos : [];
            const cursosTransformados = cursosProfesor.map(curso => {
              return { ...curso, nombre: `${curso?.nombreSede} - ${curso?.nombre}` }
            })
            setCursos(cursosTransformados)
            cargarAsignados(cursosTransformados)
          })
          .catch(error => console.log(error));
      }
    }
    cargarCursos();


  }, [])





  return (
    <>
      <div className="2xl:border-b border-gris-linea py-5">
        <h3 className="alineado-verticalmente pb-3 r7-16-negro26">
          <span className="icon-marcador text-20 mr-3"></span>
          {`${traducciones?.ficha?.recomendar?.titulo || 'Recomendar'}`}
        </h3>

        <div className="max-h-40 scroll">
          {asignados.map((asignacion, i) => {
            const { curso, estudiantesSeleccionados } = asignacion;
            return (
              <>
                <div className="grid grid-cols-2 primary-var2--bg  primary-inv-var2--bordered py-1 px-2 my-3  rounded-sm">
                  <button
                    className="focusable-red w-full  q7-18 onprimary-var1--color  una-linea-texto hover:cursor-pointer hover:text-morado-disabled" key={`asignado-${i}`}
                    onClick={() => {
                      setAsignadoActual(asignacion);
                      setModalCursoEspecificoVisible(true);
                      MicroModal.show(idModalCursoEspecifico);
                    }}
                  >
                    {`(${estudiantesSeleccionados?.length}) ${curso?.nombre}`}
                  </button>
                  <button
                    aria-label="Quitar/Remove"
                    className="focusable-red place-self-end self-center"
                    onClick={() => removerAsignacion(asignacion)}
                  >
                    <span className="icon-cerrar onprimary-var1--color text-14 hover:text-rojo cursor-pointer"></span>
                  </button>
                </div>
              </>
            )
          })}

        </div>

        <button
          className="focusable-red w-full md:w-60 xl:w-auto boton-justo mt-5 boton-blanco boton_hover_amarillo"
          onClick={() =>{ 
            setModalCursosVisible(true);
            MicroModal.show(idModalCursos);
          }}
        >
          {`${traducciones?.ficha?.recomendar?.boton || 'Recomendar'}`}
          <span className="icon-mas ml-3"></span>
        </button>
      </div>


      <>
        <Recomendar.ModalCursos
          idModal={idModalCursos}
          idLibro={idLibro}
          cursos={cursosOrdenados()}
          visible={modalCursosVisible}
          setVisible={setModalCursosVisible}
          alCambiarSeleccionados={({ curso, estudiantesSeleccionados }) => { gestionarAsignados({ curso, estudiantesSeleccionados }) }}
        />
        <Recomendar.ModalCursoEspecifico
          idModal={idModalCursoEspecifico}
          idLibro={idLibro}
          asignacion={asignadoActual}
          visible={modalCursoEspecificoVisible}
          setVisible={setModalCursoEspecificoVisible}
          alCambiarSeleccionados={({ curso, estudiantesSeleccionados }) => { gestionarAsignados({ curso, estudiantesSeleccionados }) }}
        />
      </>

    </>
  )
}

General.propTypes = {
  /**
   * El id del libro que se desea recomendar
   */
  idLibro: PropTypes.string.isRequired,
}