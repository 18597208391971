import { useState, useEffect } from "react";
import MicroModal from "micromodal";

/**componente */
import Modal from "../../../globales/modales/Modal";
import { AccionesLibroAPI } from "../../../../api/AccionesLibroAPI";
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";
import SelectorDeBusqueda from "../../../globales/elementosFormularios/SelectorDeBusqueda";
import { EstudiantesAPI } from "../../../../api/EstudiantesAPI";
import { Icon } from "@iconify/react";
import PropTypes from 'prop-types';

/**
Componente modal que se despliega, muestra los estudiantes de un curso y si tienen o no un libro recomendado, también permite editar el estado de la recomendación de todos y cada uno de los estudiantes.
 */
export const ModalCursoEspecifico = (props) => {
    // const idModal = 'modal-recomendar-a-curso=especifico-ficha-libro';
    const { visible, setVisible, asignacion, alCambiarSeleccionados, idLibro, idModal } = props;
    const { isReady, textosSubSecciones: traducciones } = useObtenerSubsecciones('libros');
    const [estudiantes, setEstudiantes] = useState([]);
    const [estudiantesSeleccionados, setEstudiantesSeleccionados] = useState([]);
    const opcionTodos = { id: 'todos', nombre: traducciones?.ficha?.recomendar?.opciones?.recomendar_todos || 'Recomendar a todos' }
    const [cargando, setCargando] = useState({
        estudiantes: false,
        guardando: false
    });

    const gestionarSeleccionados = (estudiante, esSeleccionado) => {
        const aux = estudiantesSeleccionados.filter(est => est?.id !== estudiante?.id);
        if (esSeleccionado) {
            setEstudiantesSeleccionados([...aux, { ...estudiante, asignado: true }])
        } else {
            setEstudiantesSeleccionados(aux);
        }
    }

    const abrirModal = ()=>{
        MicroModal.show(idModal)
    }

    const cerrarModal = ()=>{
        MicroModal.close(idModal)
    }

    const asignar = async () => {
        setCargando({ ...cargando, guardando: true });
        const idsSeleccionados = estudiantesSeleccionados.map(est => est?.id);
        let seleccionados = estudiantesSeleccionados;
        if (idsSeleccionados.includes('todos')) {
            setEstudiantesSeleccionados(estudiantes);
            seleccionados = estudiantes;
        }
        await Promise.all([
            AccionesLibroAPI.multiplesAcciones({
                accion: 'Ocultar',
                codigoEstudiantes: estudiantes.map(est => est?.codigo),
                idLibros: [idLibro]
            }),
            AccionesLibroAPI.multiplesAcciones({
                accion: 'Mostrar',
                codigoEstudiantes: seleccionados.map(est => est?.codigo),
                idLibros: [idLibro]
            }),
        ])
            .then(resultado => {
                alCambiarSeleccionados &&
                    alCambiarSeleccionados({
                        curso: asignacion?.curso,
                        estudiantesSeleccionados: seleccionados
                    })
            })
            .catch(error => console.log(error))
        setVisible(false);
        cerrarModal();
        
        setCargando({ ...cargando, guardando: false });
    }


    useEffect(() => {
        const cargarEstudiantes = async () => {
            setCargando({ ...cargando, estudiantes: true });
            await EstudiantesAPI.obtenerPorCurso(asignacion?.curso?.id)
                .then(resultado => {
                    const recomendadosIDs = Array.isArray(asignacion?.estudiantesSeleccionados) ?
                        asignacion?.estudiantesSeleccionados.map(estudiante => estudiante?.id)
                        : [];
                    setEstudiantes(Array.isArray(resultado) ?
                        resultado.map(estudiante => {
                            let asignado = false;
                            if (recomendadosIDs.includes(estudiante?.id)) {
                                asignado = true;
                            }
                            return { ...estudiante, asignado: asignado };
                        })
                        : []
                    )
                    setCargando({ ...cargando, estudiantes: false });
                })
                .catch(error => {
                    console.log(error)
                    setCargando({ ...cargando, estudiantes: false });
                })
        }
        if (Boolean(asignacion)) {
            cargarEstudiantes();
            setEstudiantesSeleccionados(asignacion?.estudiantesSeleccionados.map(est => {
                return { ...est, asignado: true }
            }));
        }
    }, [asignacion?.curso])



    // useEffect(() => {
    //     try {
    //         if (visible) {
    //             MicroModal.show(idModal);
    //         } else {
    //             MicroModal.close(idModal)
    //         }
    //     } catch (error) {

    //     }

    // }, [visible])

    useEffect(() => {
        const ids = estudiantesSeleccionados.map(estudiante => estudiante?.id);
        setEstudiantes(estudiantes.map(estudiante => {
            let esAsignado = false;
            if (ids.includes(estudiante?.id)) {
                esAsignado = true;
            }
            return { ...estudiante, asignado: esAsignado }
        }))
    }, [estudiantesSeleccionados])


    const esAsignado = (estudiante) => {
        return estudiante?.asignado;
    }

    return (<>
        <Modal nombreModal={idModal}
            alCerrarModal={() => {
                setVisible(false);
                cerrarModal();
            }}
        >
            <div className="pb-3">
                <h1 className="quicksand-700 onbackground-var2--color text-20 text-center ">{`${traducciones?.ficha?.recomendar?.modal?.titulo || 'Recomendar este libro a'}`}</h1>
                <h1 className="w-full p-2 blue-inv-var1--bg m-1 text-center background-var3--color font-raleway font-bold">{asignacion?.curso?.nombre || 'N/A'}</h1>
                <div className="background-var3--bg scroll mt-5 h-52" style={{ minHeight: 110 }}>

                    {
                        cargando.estudiantes == false ?
                            (<>
                                {estudiantes?.length > 0 ?
                                    <ul>
                                        {[opcionTodos, ...estudiantes].map(estudiante => {
                                            const { nombre, id } = estudiante;
                                            return (
                                                <li
                                                    key={id}
                                                    className="px-3 py-3 alineado-verticalmente cursor-pointer hover:primary-inv-var5--bg"

                                                >
                                                    <label className=" w-full h-7 relative mr-3 cursor-pointer" role="checkbox">
                                                        <input
                                                            checked={esAsignado(estudiante)}
                                                            type="checkbox"
                                                            onChange={(e) => gestionarSeleccionados(estudiante, e.target.checked)}
                                                        />
                                                        <i className="check" ></i>
                                                        <p className="raleway-400 onbackground-var2--color absolute left-10 top-0.5">{nombre}</p>
                                                    </label>
                                                </li>
                                            )
                                        })}
                                    </ul> :
                                    <div className="min-w-full w-full h-full min-h-full flex center">

                                        <h1 className="onbackground-var2--color font-bold font-quicksand text-center">
                                            {`${traducciones?.ficha?.recomendar?.modal?.sin_estudiantes || 'No hay estudiantes en este curso.'}`}
                                        </h1>

                                    </div>
                                }
                            </>)
                            : <div className="flex center min-w-full min-h-full">
                                <Icon icon="fa:spinner" className="primary-inv-var2--color text-28 animate-spin" />
                            </div>
                    }

                </div>
            </div>
            <div className="flex center w-full">
                <button
                    aria-label="Actualizar"
                    disabled={(estudiantes.length <= 0) ? true : false}
                    className="focusable-red boton-justo text-16 boton-amarillo boton_hover_morado alineado-verticalmente  flex  justify-between text-center"
                    onClick={() => asignar()}
                >
                    {cargando.guardando == true &&
                        <Icon icon={`fa:spinner`} className={'text-22 animate-spin mx-1'} />
                    }
                    {traducciones?.ficha?.recomendar.modal?.actualizar || 'Actualizar'}
                </button>

            </div>
        </Modal>
    </>)
}

ModalCursoEspecifico.propTypes = {
    /**
     * propiedad booleana que defiene si el modal debe ser visible o no
     */
    visible: PropTypes.bool, 
    /**
     * Función que modifica el estado de visibilidad del modal, recibe un parametro booleano.
     */
    setVisible: PropTypes.func, 
    /**
     * objeto con la información del curso y los estudiantes seleccionados 
     */
    asignacion: PropTypes.shape({
        curso:PropTypes.object,
        estudiantesSeleccionados:PropTypes.array
}), 
/**
 * Función que es llamada cuando se modifica los estudiantes seleccionados en el modal, en su parametro se envía el objeto con el curso y los estudiantes seleccionados
 */
    alCambiarSeleccionados: PropTypes.func, 
    /**
     * id del libro a reocmendar
     */
    idLibro: PropTypes.string, 
    /**
     * id del modal
     */
    idModal: PropTypes.string
}
