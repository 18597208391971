
import { useState, useEffect } from "react";
import MicroModal from "micromodal";

/**componente */
import Modal from "../../../globales/modales/Modal";
import { AccionesLibroAPI } from "../../../../api/AccionesLibroAPI";
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";
import SelectorDeBusqueda from "../../../globales/elementosFormularios/SelectorDeBusqueda";
import { EstudiantesAPI } from "../../../../api/EstudiantesAPI";
import { Icon } from "@iconify/react";
import PropTypes from "prop-types";
/**
    Este componente muestra la lista de los cursos existentes en el instituto y a sus estudiantes, permitiendo recomendar un libro, una vez que el libro se recomienda a al menos un estudiante de un curso, el curso no aparecera en este modal y solo se podrá editar haciendo click en el curso en la sección de recomendación en laficha del libro.
 */
export const ModalCursos = (props) => {
    const { visible, setVisible, cursos, alCambiarSeleccionados, idLibro, idModal } = props;
    const { isReady, textosSubSecciones: traducciones } = useObtenerSubsecciones('libros');
    const [estudiantes, setEstudiantes] = useState([]);
    const [cursoSeleccionado, setCursoSeleccionado] = useState();
    const [estudiantesSeleccionados, setEstudiantesSeleccionados] = useState([]);
    const opcionTodos = { id: 'todos', nombre: traducciones?.ficha?.recomendar?.opciones?.recomendar_todos || 'Recomendar a todos' }
    const [cargando, setCargando] = useState({
        estudiantes: false,
        guardando: false
    });


    const abrirModal = ()=>{
        MicroModal.show(idModal)
    }

    const cerrarModal = ()=>{
        MicroModal.close(idModal)
    }

    const gestionarSeleccionados = (estudiante, esSeleccionado) => {
        const aux = estudiantesSeleccionados.filter(est => est?.id !== estudiante?.id);
        if (esSeleccionado) {
            setEstudiantesSeleccionados([...aux, estudiante])
        } else {
            setEstudiantesSeleccionados(aux);
        }
    }

    const asignar = async () => {
        setCargando({ ...cargando, guardando: true });
        const idsSeleccionados = estudiantesSeleccionados.map(est => est?.id);
        let seleccionados = estudiantesSeleccionados;
        if (idsSeleccionados.includes('todos')) {
            setEstudiantesSeleccionados(estudiantes);
            seleccionados = estudiantes;
        }
        await AccionesLibroAPI.multiplesAcciones({
            accion: 'Mostrar',
            codigoEstudiantes: seleccionados.map(est => est?.codigo),
            idLibros: [idLibro]
        })
            .then(resultado => {
                setVisible(false);
                cerrarModal();
                alCambiarSeleccionados &&
                    alCambiarSeleccionados({
                        curso: cursoSeleccionado,
                        estudiantesSeleccionados: seleccionados
                    })
            })
            .catch(error => console.log(error))
        setVisible(false);
        cerrarModal();
        setCargando({ ...cargando, guardando: false });
    }

    useEffect(() => {
        if (visible) {
            setCursoSeleccionado(undefined)
            setEstudiantes([]);
        }
    }, [visible])

    useEffect(() => {
        const cargarEstudiantes = async () => {
            setCargando({...cargando, estudiantes:true})
            await EstudiantesAPI.obtenerPorCurso(cursoSeleccionado?.id)
            .then(resultado => {
                setEstudiantes(Array.isArray(resultado) ? resultado : [])
            })
            .catch(error => console.log(error))
            setCargando({...cargando, estudiantes:false})
        }
        cargarEstudiantes();
        setEstudiantesSeleccionados([]);
    }, [cursoSeleccionado])



    return (<>
        <Modal nombreModal={idModal}
            alCerrarModal={() =>{
                setVisible(false);
                cerrarModal();
            }}
        >
            <div className="pb-3">
                <h1 className="quicksand-700 onbackground-var2--color text-20 text-center ">
                    {`${traducciones?.ficha?.recomendar?.modal?.titulo || 'Recomendar este libro a'}`}</h1>
                <SelectorDeBusqueda
                    cambiarElegido={({ opcion }) => { setCursoSeleccionado(opcion) }}
                    opciones={cursos}
                    superposicion={true}
                    textoDefault={`${traducciones?.ficha?.recomendar?.modal?.seleccion_curso || 'Selecciona un curso'}`}
                    textoElegido={cursoSeleccionado?.nombre}
                    alturaLista={{
                        max: '180px'
                    }}
                />
                <div className="background-var3--bg scroll mt-5 h-52" style={{ minHeight: 110 }}>
                    {
                        cargando?.estudiantes == false ?
                            (<>
                                {estudiantes?.length > 0 ?
                                    <ul>
                                        {[opcionTodos, ...estudiantes].map(estudiante => {
                                            const { nombre, id } = estudiante;
                                            return (
                                                <li
                                                    key={id}
                                                    className="px-3 py-3 alineado-verticalmente cursor-pointer hover:primary-inv-var5--bg"

                                                >
                                                    <label className=" w-full h-7 relative mr-3 cursor-pointer" role="checkbox">
                                                        <input
                                                            type="checkbox"
                                                            onChange={(e) => gestionarSeleccionados(estudiante, e.target.checked)}
                                                        />
                                                        <i className="check" ></i>
                                                        <p className="raleway-400 onsurface-var1--color absolute left-10 top-0.5">{nombre}</p>
                                                    </label>
                                                </li>
                                            )
                                        })}
                                    </ul> :
                                    <div className="min-w-full w-full h-full min-h-full flex center">
                                        {
                                            Boolean(cursoSeleccionado) == false ?
                                                <h1 className=" font-bold font-quicksand text-center primary-inv-var2--color text-22">
                                                    {`${traducciones?.ficha?.recomendar?.modal?.aviso || 'Selecciona un curso para ver los estudiantes'}`}
                                                </h1> :
                                                <h1 className="onbackground-var2--color font-bold font-quicksand text-center">
                                                    {`${traducciones?.ficha?.recomendar?.modal?.sin_estudiantes || 'No hay estudiantes en este curso.'}`}
                                                </h1>
                                        }
                                    </div>
                                }
                            </>)
                            : <div className="flex center min-w-full min-h-full">
                                <Icon icon="fa:spinner" className="primary-inv-var2--color text-28 animate-spin" />
                            </div>
                    }

                </div>
            </div>
            <div className="flex center w-full">
                <button
                    disabled={(Boolean(cursoSeleccionado) == false || estudiantes.length <= 0) ? true : false}
                    className="focusable-red boton-justo text-16 boton-amarillo boton_hover_morado alineado-verticalmente  flex  justify-between text-center"
                    onClick={() => asignar()}
                >

                    {cargando.guardando == true &&
                        <Icon icon={`fa:spinner`} className={'text-22 animate-spin mx-1'} />
                    }
                    {traducciones?.ficha?.recomendar.modal?.actualizar || 'Actualizar'}
                </button>

            </div>
        </Modal>
    </>)
}

ModalCursos.propTypes = {
    /**
     * propiedad booleana que defiene si el modal debe ser visible o no
     */
    visible: PropTypes.bool, 
    /**
     * Función que modifica el estado de visibilidad del modal, recibe un parametro booleano.
     */
    setVisible: PropTypes.func, 
    /**
     * Arreglo de los cursos a los que se quiere poder recomendar un libro.
     */
    cursos: PropTypes.array, 
/**
 * Función que es llamada cuando se modifica los estudiantes seleccionados en el modal, en su parametro se envía el objeto con el curso y los estudiantes seleccionados
 */
    alCambiarSeleccionados: PropTypes.func, 
    /**
     * id del libro a reocmendar
     */
    idLibro: PropTypes.string, 
    /**
     * id del modal
     */
    idModal: PropTypes.string
}