import { VisorAPI } from "../../../api/VisorAPI";

/**
 * Función para manejar marcadores en archivos EPUB.
 * @returns {{saveMarker:({bookId, marker}), getMarker:({bookId})}} Objeto con métodos para guardar y obtener marcadores.
 * @example
 * const markerManager = useEpubMarkerManager();
 * markerManager.saveMarker({ bookId: '123', marker: 'epubcfi(/6/10!/4/2[anchor10746]/526/2/1:0)' });
 * markerManager.getMarker({ bookId: '123' });
 */
export const useEpubMarkerManager = () => {

	const saveMarker = async ({ bookId, marker }) => {
        try {
            return await VisorAPI.saveBookMarker({bookId, marker});
        } catch (error) {
            console.log('error saving marker', error);
            return null;
        }
	};
    
    const getMarker = async ({bookId})=>{
        try {
            const marker = await VisorAPI.getBookMarker({bookId:bookId});
            return marker?marker:null;
        } catch (error) {
            console.log('error loading marker', error);
            return null;
        }
    }

	return {
		saveMarker,
        getMarker
	};
};
