import { useEffect, useState } from "react";
import FunctionsToSelect from "./functionsToSelect";
import SuscripcionActiva from "./SuscripcionActiva";
import { Route, Switch, useParams, Redirect } from "react-router-dom";
import FichaLibro from "./FichaLibro";
import { useDispatch, useTrackedState } from '../../store';
import EligiendoLibros from "./EscogiendoLibros";

/**componentes */
import SkeletonLibros from "../../components/libros/Skeletons/SkeletonLibros";
import SkeletonFichaLibro from "../../components/libros/Skeletons/SkeletonFichaLibro";
import { SeleccionLibrosInicialAPI } from "../../api/SeleccionLibrosInicialAPI";
import { LibrosAPI } from "../../api/LibrosAPI";
import { adaptarPortadaLibros } from "../../utilities/adaptadores";
import { VerificarSeleccionContainer } from "./VerificarSeleccion";
import getUrlParam from "../../utilities/function/getUrl";
import { CursosAPI } from "../../api/CursosAPI";
import { ProfesoresAPI } from "../../api/ProfesoresAPI";

/**
 * es el componente principal del modulo de Libros que realiza la lógica correspondiente para delegar que container (EscogiendoLibros, SuscripcionActiva o FichaLibro) debe ser renderizado y pasar la información correspondiente a cada container.
 */
const Libros = () => {
  const state = useTrackedState();
  const { planActivo } = state;

  //Comunes a ambos estados(suscripcion activa, escogiendo libros)
  const [librosData, setLibrosData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [multiselectState, setMultiselectState] = useState({ active: false, librosToSelect: [], idLibros: [] })
  const [processingItems, setProcessingItems] = useState(false);
  const [isLoadingLibros, setIsLoadingLibros] = useState(false);
  const [cargandoPaquete, setCargandoPaquete] = useState(false);
  const [cargandoLibroInfo, setCargandoLibroInfo] = useState(false);
  const [selectorOpcionSeleccionada, setSelectorOpcionSeleccionada] = useState(undefined);

  //textos
  /*   const { isReady: isReadyLibros, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('libros'); */

  //Suscripción activa
  const [cursosData, setCursosData] = useState({});
  const [itemsActivos, setItemsActivos] = useState({ sede: '', curso: '' });

  //Escoger libros
  const [paquetesDisponibles, setPaquetesDisponibles] = useState([]);
  const [paqueteParaSeleccionar, setPaqueteParaSeleccionar] = useState('')
  const eligiendoLibros = !!planActivo && planActivo.eligiendoLibros;

  //Ficha del libro
  const [informacionLibro, setInformacionLibro] = useState({})
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    getInitialData();

    dispatch({
      type: "SECCION_LIBROS_TEMPORAL",
      property: "modoFiltro",
      value:null
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const cargarInfoLibro = async () => {
      setCargandoLibroInfo(true)
      if (params.idLibro !== undefined) {
        //AE: Esta API solo busca libros en el paquete 1 por lo que no todos los libros van a aparecer o devolver datos, se hizo así para hacerlo más rápido ya que en la suscripción activa neesitamos el objeto de cursos, pero el paquete de todos los libros no tiene asignada esa propiedad. Cuando se haga la implementación real no debería haber problema
        await LibrosAPI
          .todosBuscarPorId(params.idLibro)
          .then(libro => {
            if (libro)
              setInformacionLibro(adaptarPortadaLibros([libro])[0])
          })
          .catch(error => console.log(error))
        setCargandoLibroInfo(false)
      }
    }
    getInitialData();
    cargarInfoLibro();
  }, [params.idLibro])

  useEffect(() => {
    if ( Boolean(informacionLibro) && Object.keys(informacionLibro).length > 0) {
      let libro = librosData.libros.find(item => item.idLibro === informacionLibro.idLibro);
      setInformacionLibro(libro);
    }
  }, [librosData])


  const getInitialData = async () => {
    setIsLoading(true);
    try {
      if (eligiendoLibros) {
        let paquetes = await SeleccionLibrosInicialAPI.paquetesDisponibles();
        const paquetesPermitidos = paquetes.filter(paquete => paquete.autorizado === 0);
        let paquetePorDefecto = paquetesPermitidos[0];
        if (Boolean(selectorOpcionSeleccionada)) {
          paquetePorDefecto = selectorOpcionSeleccionada;
        }
        let libros = await LibrosAPI.librosPorPaquete(paquetePorDefecto?.nombre || '');
        if (Array.isArray(libros)) {
          setLibrosData({ libros: adaptarPortadaLibros(libros) });
          setPaquetesDisponibles(paquetesPermitidos);
          setPaqueteParaSeleccionar(paquetePorDefecto);
        }
      }
    } catch (error) {
      console.log(error);
    }
    //ESCOGER LIBROS


    //SUSCRIPCIÓN ACTIVA
    if (!eligiendoLibros) {
      let cursos = await CursosAPI.obtenerCursos();
      if (planActivo?.tipoUsuario === 'profesor') {
        let profesores = [];
        await ProfesoresAPI.obtenerTodos()
          .then(resultado => {
            profesores = resultado;
          })
          .catch(error => console.log(error))
        const profesorActual = profesores.find(profesor => profesor?.correo === state?.datosDeUsuario?.correo)
        cursos = (Boolean(profesorActual) && Array.isArray(profesorActual?.cursos)) ?
        profesorActual?.cursos.reduce((acc, curr) => {
            acc[`${curr?.idSede}`] ={
              cursos: profesorActual?.cursos?.filter(curso=>curso?.idSede===curr?.idSede)
            }
            return acc;
          }, {})
          : [];
   
      }
      //AE: TODO: Llamar los clubs disponibles en la Sede que debería ser el api_obtenerClubs o algo así.
      if (cursos) {
        setCursosData(cursos)
        let sedes = Object.keys(cursos);
        let sedeActiva = sedes[0];
        let cursoActivo = cursos[sedeActiva]?.cursos[0]?.id || '';
        //revisa si la url tiene como parametros la sede y el curso debido a que posiblemente este siendo redireccionado desde otra parte de la pagina.
        //si es asi debería cargar la información correspondiente a esos parametros
        let parametroSede = getUrlParam('sede');
        let parametroCurso = getUrlParam('curso');
        let sedeYCurso = Boolean(parametroSede && parametroCurso);
        if (sedes.length > 0) {
          if(parametroSede && parametroCurso == false){
            sedeActiva = parametroSede;
            cursoActivo = cursos[sedeActiva]?.cursos[0]?.id || '';
          }
          else if (sedeYCurso) {
            sedeActiva = parametroSede;
            cursoActivo = cursos[sedeActiva]?.cursos.find(curso=>curso?.id === parametroCurso)?.id || '';
          }
          else if (sedeYCurso==false && itemsActivos?.curso && itemsActivos?.sede){
              sedeActiva = itemsActivos?.sede || ''
              cursoActivo =  cursoActivo = cursos[sedeActiva]?.cursos.find(curso=>curso?.id === itemsActivos?.curso)?.id || '';
            
          }
    
          //si no tiene parametros en la url mantiene guardada la ultima la ultima sede y curso seleccionados.
         
          const items = { sede: sedeActiva, curso: cursoActivo }
          setItemsActivos(items);
          await getLibrosPorSede(sedeActiva)
            .then(libros => {
              setLibrosData({ libros: adaptarPortadaLibros(libros) });
            })
            .catch(error => console.log(error))
        }
      }
    }
    setIsLoading(false);
  }


  // FUNCIONES PARA SUSCRIPCION ACTIVA
  const getLibrosPorSede = async (idSede) => {
    let libros = [];
    await LibrosAPI.buscarPorSede(idSede)
      .then(lib => libros = [...lib])
      .catch(error => console.log(error));
    return libros
  }


  const setSedeActiva = async (idSede) => {
    setIsLoadingLibros(true);
    if (!cursosData[idSede].cursos || cursosData[idSede].cursos.length === 0) {
      console.error('Esta sede no tiene cursos asignados.')
      return
    }
    const items = { ...itemsActivos, sede: idSede, curso: cursosData[idSede].cursos[0].id }
    setItemsActivos(items);
    setSelectorOpcionSeleccionada(items);
    await getLibrosPorSede(idSede)
      .then(libros => {
        setLibrosData({ libros: adaptarPortadaLibros(libros) });
        setIsLoadingLibros(false);
      })
      .catch(error => console.log(error));
  }

  const setCursoActivo = (idCurso) => {
    const items = { ...itemsActivos, curso: idCurso }
    setItemsActivos(items)
    setSelectorOpcionSeleccionada(items);
  }

  //FUNCIONES ESCOGIENDO LIBROS
  const setPaqueteActivo = async (paqueteId) => {
    //AE: Es mejor hacerlo con un API para la exactitud de los datos y para mejorar el performance en el evento onChange.
    setCargandoPaquete(true);
    setIsLoadingLibros(true);
    let paqueteInfo = paquetesDisponibles.find(item => item.nombre === paqueteId);
    setSelectorOpcionSeleccionada(paqueteInfo);
    setPaqueteParaSeleccionar(paqueteInfo);
    await SeleccionLibrosInicialAPI.obtenerPaquete(paqueteId)
    .then(resultado=>{
      setCargandoPaquete(false);
      setPaqueteParaSeleccionar(resultado);
    })
    .catch(error=>{console.log(error)})
    
    // console.log('info paquete',paqueteInfo)
    
    await LibrosAPI
      .librosPorPaquete(paqueteId)
      .then(libros => {
        setLibrosData({ libros: adaptarPortadaLibros(libros) });
        setIsLoadingLibros(false);
      })
      .catch(error => console.log(error))
  }

  const selectFunctions = FunctionsToSelect({
    librosData,
    setLibrosData,
    processingItems,
    setProcessingItems,
    multiselectState,
    setMultiselectState,
    itemsActivos
  });


  return (
    <>
      <Switch>
        <Route exact path='/libros' >
          {
            isLoading ?
              <SkeletonLibros />
              :
              <>
                {
                  eligiendoLibros == false ?
                    <SuscripcionActiva
                      librosData={librosData}
                      cursosData={cursosData}
                      itemsActivos={itemsActivos}
                      sedesFilters={{ setSedeActiva, setCursoActivo }}
                      setLibrosData={setLibrosData}
                      isLoadingLibros={isLoadingLibros}
                      setIsLoadingLibros={setIsLoadingLibros}
                      selectFunctions={selectFunctions}
                      processingItems={processingItems}
                      multiselectState={multiselectState}
                    />
                    : <>
                      <Redirect exact to={'/libros/seleccion-libros'} />
                    </>
                }
              </>
          }
        </Route>
        <Route exact path='/libros/seleccion-libros' >
          {
            isLoading ?
              <SkeletonLibros />
              :
              <>
                {
                  eligiendoLibros ?
                    <EligiendoLibros
                      librosData={librosData}
                      setLibrosData={setLibrosData}
                      selectFunctions={selectFunctions}
                      processingItems={processingItems}
                      multiselectState={multiselectState}
                      paquetesDisponibles={paquetesDisponibles}
                      paqueteParaSeleccionar={paqueteParaSeleccionar}
                      cargandoPaquete={cargandoPaquete}
                      isLoadingLibros={isLoadingLibros}
                      setIsLoadingLibros={setIsLoadingLibros}
                      setPaqueteActivo={setPaqueteActivo}
                    />
                    : <>
                      <Redirect exact to={'/libros'} />
                    </>
                }
              </>
          }
        </Route>

        <Route
          path={'/libros/seleccion-libros/verificar'}
        >
          {
            eligiendoLibros ?
              <VerificarSeleccionContainer /> : <Redirect to={'/libros'} />
          }
        </Route>

        <Route exact path='/fichas/:idLibro/:tituloLibro'>
          {cargandoLibroInfo ?
            <SkeletonFichaLibro />
            :
            <FichaLibro
              escogiendoLibrosProps={{ 
                paqueteActual:paqueteParaSeleccionar,
                paquetesDisponibles
              }}
              informacionLibro={informacionLibro}
              eligiendoLibros={eligiendoLibros}
            />
          }

        </Route>
      </Switch>
    </>
  )
}

export default Libros;