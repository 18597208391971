import axios from "axios";

export class ProfesoresAPI {

    // DEFINICIÓN DE LA ESTRUCTURA DE UN PROFESOR
    /**
     * @typedef { 
     {
            "id": string,
            "username": string,
            "nombre": string,
            "correo": string,
            "avatar":string,
            "rector": string,
            "cursos":  [
                        {
                            "ïdSede":string,
                            "nombreSede",
                            "id": string,
                            "nombre": string
                        }
                    ],
            "clubs": []
        }
     * } Profesor
*/
//---------------------------------------------------------------------------------
    // EMPIEZAN LOS SERVICIOS

        /**
         * Devuelve un arreglo con todos los profesores de una institutcion
         * @param {string} idCurso 
         * @returns {Promise<Profesor[]>} 
         */
    static async obtenerTodos() {
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const URL = 'profesores/obtenerListadoProfesores'
            const response = await axios({
                method: 'POST',
                url: URL,

            })
            const data = response.data.data;
            if (Array.isArray(data) && data.length <= 0) {
                return [];
            }
            let profesores = [];

            data.forEach(profesor=>{
                const objetoCursos = Object.values(profesor?.cursos);
                const idSedes = Object.keys(profesor?.cursos);
                const cursos = [];
                objetoCursos.forEach((sede,i)=>{
                    return sede?.cursos.forEach(curso=>{
                        cursos.push({...curso, idSede:idSedes[i],nombreSede:sede.nombre })
                    })
                })
                profesores.push(
                    {...profesor, cursos:cursos}
                )
            })
            return profesores;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     * Devuelve el primer profesor que tenga asignado el correo especificado, si no se encuentra devuelva undefined.
     * @param {string} correo 
     * @returns {Promise<Profesor|undefined>}
     */
    static async obtenerPorCorreo(correo) {
        try {
           const profesores = await ProfesoresAPI.obtenerTodos();
           const coincidencias = profesores.filter(profesor=>profesor?.correo === correo);
            const profesor = coincidencias.length > 0? coincidencias[0]:undefined;
            return profesor;
        } catch (error) {
            return Promise.reject(error);
        }
    }




}
