import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { ReactReader } from "react-reader"; /* A simple epub-reader with left/right button and chapter navigation*/
import HTMLFlipBook from "react-pageflip";
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import { useEpubMarkerManager } from "./hooks/use-epub-marker-manager";
/* import getUrlParam from "../../utilities/getUrl"; */
const screenfull = require('screenfull');

/**
 * El componente padre (Container) realiza la lógica 
 correspondiente para traer los datos del libro 
 basandose en el parametro ID del libro el cual se pasa 
 en la URL, una vez se tiene la información  se los pasa a este componente que se encargara de  renderizar el visor 
 para la lectura del libro especificado, ejecutando toda la lógica que está en la carpeta 'standalone', es decir, que este componente es un intermediario para que la librería del visor pueda realizar su lógica interna para poner a disposición la lectura de un libro dependiendo del tipo que sea. Por otro lado, mientras se lee el libro, el componente 
 padre realiza la la petición al API que recopila las estadísticas de lectura.
 */
const VisorComponent = (props) => {
  const epubMarkerManager = useEpubMarkerManager();
  const [epubMarker, setEpubMarker] = useState({
    marker:'',
    loaded:false
  });
  const [currentEpubLocation, setCurrentEpubLocation] = useState('');
  const { datosLibro, isLoading, traducciones, pathAnterior } = props;
  const bookId = datosLibro?.idLibro;
  const { botones, descubre, footer } = traducciones;

  const [fontSize, setFontSize] = useState('130%');
  const [renditionPointer, setRenditionPointer] = useState(null);
  const [isShowDescubre, setisShowDescubre] = useState(false);
  const visorScreen = useRef(null)
  const [isScreenFull, setIsScreenFull] = useState(false)
  const [isShowFontSize, setIsShowFontSize] = useState(false)

  const [currentPage, setCurrentPage] = useState(0);
  const [pageFlip, setPageFlip] = useState(null);

  const history = useHistory();


  const increaseFontSize = () => {
    let num = parseInt(fontSize.replace(/[^0-9]/g, ''), 10);
    num += 10;
    setFontSize(num + "%");
    renditionPointer.themes.fontSize(num + "%");
  };

  const decreaseFontSize = () => {
    let num = parseInt(fontSize.replace(/[^0-9]/g, ''), 10);
    num -= 10;
    setFontSize(num + "%");
    renditionPointer.themes.fontSize(num + "%");
  };

  const getRendition = rendition => {
    // Set inital font-size, and add a pointer to rendition for later updates
    const spine_get = rendition.book.spine.get.bind(rendition.book.spine);
    rendition.book.spine.get = function (target) {
      let t = spine_get(target);
      while ((t === null) && target.startsWith("../")) {
        target = target.substring(3);
        t = spine_get(target);
      }
      return t;
    }
    setRenditionPointer(rendition);
  };


  const PageFlip = React.forwardRef((props, ref) => {
    return (
      <img alt="" ref={ref} src={props.src} page={props.page} />
    );
  });

  const onPage = (e) => {
    setCurrentPage(e.data);
    //console.log("Pagina= " + e.data);
  };

  const loadEpubMarker = async ()=>{
    if(datosLibro?.formato === 'epub'){
      await epubMarkerManager.getMarker({bookId:bookId})
      .then(marker=>{
        setCurrentEpubLocation(marker);
        setEpubMarker({
          marker:marker,
          loaded:true
        });
      })
      .catch(error=>console.log('Error getting epub marker:',error))
    }
  }

  const handleEpubMarker = ()=>{
    if(epubMarker?.marker === currentEpubLocation){
      setEpubMarker({
        loaded:true,
        marker:0
      })
      epubMarkerManager.saveMarker({bookId:bookId, marker:''})  
    }
    else{
      setEpubMarker({
        loaded:true,
        marker:currentEpubLocation
      })
      epubMarkerManager.saveMarker({bookId:bookId, marker:currentEpubLocation})
    }

  }


  useEffect(()=>{
    loadEpubMarker();
  },[bookId])

  const renderVisor = () => {
    if (datosLibro.formato === "epub") {
      return (
        <div style={{ position: "relative", height: "100%" }}>
          {" "}
          {
            epubMarker?.loaded === true&&
            <ReactReader
              url={`${process.env.REACT_APP_ASSETS_URL_DOCUMENTOS}${datosLibro.Archivos[0]}`}
              title={datosLibro.titulo}
              location={currentEpubLocation}
              tocChanged={(e)=>{console.log('TESTEST', e)}}
              locationChanged={(location)=>{
                setCurrentEpubLocation(location);
              }}
              getRendition={getRendition}
            />
          }   
          
          <button className="focusable-primary absolute top-0 right-0 m-2 p-1 px-2 rounded-md z-60 bg-negro text-blanco "
            onClick={() => setIsShowFontSize(!isShowFontSize)}
          >
            <span className="icon-cog"></span>
          </button>
          
            <button className={`text-16 mr-2 focusable-primary absolute top-0 right-10 m-2 p-1 px-2 rounded-md z-60 ${currentEpubLocation===epubMarker?.marker?'bg-morado text-blanco ':'bg-negro text-blanco '}`}
              onClick={() => handleEpubMarker() }
            >
              <i className="icon-marcador"></i>
            </button>

          {isShowFontSize &&
            <>
              <div className="font-size-modal">
                <div>
                  <div className="center surface-var3--bg py-3">
                    <h3 className="col-12 py-0">Ajustes</h3>
                    <i
                      className="icon-cerrar ml-3"
                      onClick={() => setIsShowFontSize(false)}
                    />
                  </div>
                  <div className="p-3 center gap-2">
                    <p>Tamaño del texto</p>
                    <button
                      className="focusable-primary w-14 h-14 boton_hover_escalar img-80-center text-indent "
                      style={{ backgroundImage: `url(/images/menos.png)` }}
                      onClick={() => decreaseFontSize()}
                    >A-</button>
                    <button
                      className="focusable-primary w-14 h-14 boton_hover_escalar img-80-center text-indent "
                      onClick={() => increaseFontSize()}
                      style={{ backgroundImage: `url(/images/mas.png)` }}
                    >A+</button>
                  </div>
                </div>
              </div>
              <div className="overlay"
                onClick={() => setIsShowFontSize(false)}
              ></div>
            </>
          }
        </div>
      )
    } else if (datosLibro.formato === "jpgNuevo") {

      return (

        <div style={{ position: "relative", height: "100%" }}>
          <HTMLFlipBook
            width={datosLibro.image_width}
            height={datosLibro.image_height}
            size="stretch"
            showCover={true}
            minWidth={315}
            maxWidth={2000}
            minHeight={400}
            maxHeight={2000}
            onFlip={onPage}
            mobileScrollSupport={true}
            autoSize={true}
            ref={(component) => (setPageFlip(component))}
          >
            {datosLibro.Archivos.map((archivo, i) => <img alt="pagina" key={`${process.env.REACT_APP_ASSETS_URL_DOCUMENTOS}${archivo}`} page={i} src={`${process.env.REACT_APP_ASSETS_URL_DOCUMENTOS}${archivo}`}  ></img>)}
          </HTMLFlipBook>
        </div>
      )
    } else if (datosLibro.formato === "pdf") {
      return (
        <iframe title="Lector" style={{ pointerEvents: 'auto' }} id="cuadro" src={`/standalone/visor/?idLibro=${datosLibro.idLibro}`} height="100%" width="100%" allowFullScreen="" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true"></iframe>
      )
    } else if (datosLibro.formato === "jpg") {
      return (
        <iframe title="Lector" style={{ pointerEvents: 'auto' }} id="cuadro" src={`/standalone/visor/?idLibro=${datosLibro.idLibro}`} height="100%" width="100%" allowFullScreen="" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true"></iframe>
      )
    }
    else if (datosLibro.formato === "htmlScroll") {
      return (
        <iframe title="Lector" style={{ pointerEvents: 'auto' }} className="iframeClass" id="cuadro" src={`${process.env.REACT_APP_ASSETS_URL_DOCUMENTOS}${datosLibro.Index}`} height="100%" width="100%" allowFullScreen="" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true"></iframe>
      )
    } else {
      return (
        <iframe title="Lector" scrolling="no" style={{ pointerEvents: 'auto' }} className="iframeClass" id="cuadro" src={`${process.env.REACT_APP_ASSETS_URL_DOCUMENTOS}${datosLibro.Index}`} height="100%" width="100%" allowFullScreen="" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true"></iframe>
      )
    }
  }

  const linkDescubre = (number) => {

    return (
      <a
        href={datosLibro[`link${number}`]}
        target="_blank"
        className="card"
      >
        {datosLibro[`textLink${number}`]} <span className="ml-3 icon-atras transform rotate-180"></span>
      </a>
    )
  }

  /**Al dar click en descubre muestra el contenido */
  const showDescubre = () => setisShowDescubre(true)

  /**Al dar click en ... oculta el contenido de descubre*/
  const hideDescubre = () =>  setisShowDescubre(false)

  /**contenido de descubre */
  const descubreContent = () => {
    return (
      <div
        className={`descubre-container`}
        style={{ 
          backgroundImage: `url(/images/background.png)`,
          right: isShowDescubre === true ? 0 : '-100%'
        }}
      >
        <button
          className="focusable-red boton-justo boton-amarillo boton_hover_morado absolute top-5 right-5"
          onClick={() => hideDescubre()}>
          <span className="icon-cerrar"></span>
        </button>

        {
          datosLibro.link1 !== '' &&
          <h2 className="center text-20 mt-16 raleway-700 secondary-inv-var1--color">
            {botones.descubre} <span className="ml-3 icon-mas"></span>
          </h2>
        }


        <div className={`${(datosLibro.link1 !== '') ? "" : "mt-10"} mb-5 pt-5`}>
          {datosLibro.Actividad &&
            <>
              <h3 className="titulo-descubre">{descubre.actividades}</h3>
              <a
                className="card"
                href={`${process.env.REACT_APP_ASSETS_URL}${datosLibro.Actividad?.contentURL}`}
                target="_blank"
              >
                {datosLibro.Actividad?.tituloActividad} <span className="ml-3 icon-atras transform rotate-180"></span>
              </a>
            </>
          }
          {datosLibro.link1 !== '' &&
            <>
              <h3 className="titulo-descubre">{descubre.contenido}</h3>
              {linkDescubre(1)}
            </>
          }
          {datosLibro.link2 !== '' &&
            <> {linkDescubre(2)} </>
          }
          {datosLibro.link3 !== '' &&
            <>{linkDescubre(3)}</>
          }
          
          {
            datosLibro?.recomendadoID1 !== "" && datosLibro?.recomendadoID2 !== "" &&

            <div className="mt-4">
            <h3 className="titulo-descubre">{descubre.libros_relacionados}</h3>
            <div className="grid grid-cols-2 gap-4">

              <Link to={datosLibro?.hrefRecomen1} className="focusable-primary boton_hover_escalar">
                <img
                  alt="Portada libro recomendado 1"
                  src={`${process.env.REACT_APP_ASSETS_URL}${datosLibro?.hrefRecomen1Portada}`}
                />
              </Link>
              <Link to={datosLibro?.hrefRecomen2} className="focusable-primary boton_hover_escalar">
                <img
                  alt="Portada libro recomendado 2"
                  src={`${process.env.REACT_APP_ASSETS_URL}${datosLibro?.hrefRecomen2Portada}`}
                />
              </Link>
            </div>
          </div>
          
          }


        </div>
      </div>
    )
  }

  return (
    <>
      {isLoading && (
        <div className="h-screen center pt-5">
          <span className="ml-10 icon-spinner9 animate-spin text-40 primary-inv-var2--color"></span>
        </div>
      )}
      {!isLoading &&
        <div className="overflow-hidden h-screen relative">
          <div className="visor-header">
            <div className=" p-3 lg:px-5 lg:py-3 grid grid-cols-12 grap-4">

              <div className="alineado-verticalmente col-span-2 md:col-span-3">
                <button className="focusable-primary boton-justo lg:boton-pequenio boton-amarillo boton_hover_morado center py-2"
                  onClick={() => {
                    history.push(pathAnterior)
                    /*const param = getUrlParam("returnUrl")
                     if (!!param) {
                      history.push(decodeURIComponent(getUrlParam("returnUrl")))
                    }  */
                    /*else {
                      // if (state.sesionActiva === 'SI' && state.cantidadLibros <= 40) {
                      //   history.push('/');
                      // } else {
                      //   history.push(datosLibro.link)
                      // }
                    }*/
                  }}
                >
                  <span className="icon-atras md:mr-5"></span> <span className="hidden md:block">{botones.volver}</span>
                </button>
              </div>

              <div className="text-center col-span-8 md:col-span-6">
                <h2 className=" quicksand-700">{datosLibro.titulo}</h2>
                <p className=" quicksand-400">{datosLibro.autor}</p>
              </div>
              <div className="alineado-izquierda-center col-span-2 md:col-span-3">
                <button className="focusable-primary boton-justo boton-amarillo boton_hover_morado center py-3" onClick={() => showDescubre()}>
                  {
                    (datosLibro.Actividad && datosLibro.link1 === '') ?
                    <>
                      <span className="hidden md:block">{descubre.actividades}</span><span className="md:ml-5 icon icon-libreta text-18"></span>
                    </>
                    :
                    <>
                      <span className="hidden md:block">{botones.descubre}</span><span className="md:ml-5 icon-mas"></span>
                    </>

                  }

                </button>
              </div>

            </div>
          </div>

          {descubreContent()}

          <div className=" mb-bookvisor-container row justify-content-center mx-0">
            <div className="mb-bookvisor" ref={visorScreen}>
              {renderVisor()}
              <button className="focusable-primary absolute right-3  bottom-10 z-60 boton-justo boton-amarillo boton_hover_morado"
                onClick={() => {
                  if (screenfull.isEnabled) {
                    screenfull.toggle(visorScreen.current);
                    if (screenfull.isFullscreen) {
                      setIsScreenFull(false)
                    } else {
                      setIsScreenFull(true)
                    }

                  }
                }}>
                {isScreenFull &&
                  <span className="icon-shrink"></span>
                }
                {!isScreenFull &&
                  <span className="icon-enlarge"></span>
                }
              </button>
            </div>
          </div>


          <div className="footer-copyright center py-1 ">
            <p>{footer.descripcion1}<span className="copy-terms"> {footer.descripcion2} <a className="links-tyc" href="/terminos-y-condiciones.php">{footer.descripcion3}</a> {footer.descripcion4} <a className="links-tyc" href="/politica-de-privacidad.php">{footer.descripcion5}</a>.</span>
            </p>
          </div>

        </div>
      }
    </>
  )
}

export default VisorComponent;

VisorComponent.propTypes = {
  /**
   * booleano que indica si esta cargando o no la información del libro
   */
  isLoading: PropTypes.bool.isRequired,
  /**
   * objeto de tipo 'LibroVisor'  que contiene la información del libro a visualizar
   */
  datosLibro: PropTypes.object.isRequired,
  /**
   * objeton con los textos dependiente del idioma que reeenderizara este componente.
   */
  traducciones:PropTypes.object.isRequired,
  /**
   *  string con la url de la página anterior
   * */ 
  pathAnterior:PropTypes.string.isRequired 
}