import axios from "axios";

export class SeleccionLibrosInicialAPI {

    // DEFINICIÓN DE LA ESTRUCTURA DE UN LIBRO
    /**
     * @typedef {{nombre: string,
            autorizado: boolean,
            estadoSeleccion:  null | 0 | 1,
            maxNumeroDeLibrosParaEscoger: number, //Si el paquete ya está escogido devuelve el la número de libros del paquete,
            fechaLímiteParaEscogerLibros: 'yyyy-mm-dd', //Si el paquete ya está escogido devuelve el la fecha final de la suscripción,
            nombre: string,
            libros:Libro[]
            }} Paquete
 */
    //---------------------------------------------------------------------------------
    // EMPIEZAN LOS SERVICIOS
    /**
     * Permite guardar los libros seleccionados en un paquete temporal, una vez se autorice se eliminan estos libros, este serviciosolo debe usarse para el momento de esocger libros
     * @param {string} idPaquete 
     * @param {Array<String>} idLibros - arreglo de las id de los libros
     * @param {boolean} confirmar - confirmar solo debe enviarse en true 1 sola vez, cuando se este seguro de que el paquete ya esta listo para ser autorizado, si se confirma por error no hay marcha atrás, por defecto enviar en false 
     * @returns {Promise<{librosAgregados:string[], librosNoAgregados:string[]}>} objetos con los ids agregados y los que no se agregaron.
     */
    static async escogerLibros(idPaquete, idLibros, confirmar) {
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const URL = 'paquetes/escogerLibros'
            const request = await axios({
                method: 'POST',
                url: URL,
                data: {
                    nombrePaquete: idPaquete,
                    idLibros: idLibros || [],
                    habilitar: confirmar ? 1 : 0
                }
            })
            const response = request.data;
            if (response.status === 1) {
                return response.data;
            } else {
                return new Error(response?.info || 'Ocurrio un error.');
            }
            //  return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     * Devuelve todos los paquetes disponibles en un instituto (el instituto se verifica con la sesión.
     * @returns {Promise<Paquete[]> Retorna arreglo de paquetes
     */
    static async paquetesDisponibles() {
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const URL = 'paquetes/paquetesDisponibles'
            const response = await axios({
                method: 'POST',
                url: URL
            })
            const data = response.data.data;
            if (data) {
                return data;
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     * Devuelve la información disponible de un paquete que este asignado a un instituto, si no existe devuelve indefinido.
     * @param {string} idPaquete 
     * @returns {Promise<Paquete | undefined>} paquete | undefined
     */
    static async obtenerPaquete(idPaquete) {
        try {

            const paquetes = await SeleccionLibrosInicialAPI.paquetesDisponibles();
            if(Array.isArray(paquetes) && paquetes.length > 0){
                const paquete = paquetes.find(paq=>paq?.nombre === idPaquete);
                return paquete;
            }else{
                return undefined;
            }
        } catch (error) {
            return Promise.reject(error);
        }



    }

}