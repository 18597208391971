import { useEffect, useState } from "react";
import { DetallesSesionesAPI } from "../../../api/estadisticas/DetallesSesionesAPI";
import { COMPARACION_COLORES_DISPONIBLES } from "../../../containers/estadisticas/Constantes";
import useObtenerSubsecciones from "../../../containers/helpers/hooks/useObtenerSubsecciones";
import { useTrackedState } from "../../../store";
import { FiltroFechas } from "../../../utilities/gestion_fechas/FiltrosFechas";
import Grafica from "../../globales/estadisticas/Grafica";
import TooltipGrafica from "../../globales/estadisticas/TooltipGrafica"
import PropTypes from 'prop-types';

const TIPO_GRAFICAS = {
    totalSesiones: {
        id: 'total_sesiones',
        traduccion: 'sesiones',
        propiedad: 'numeroDeSesiones', // propiedad para buscar en los objetos estadisticos.
        ejes:{
            y:{
                traduccion:'total_sesiones_lectura',
                textoPorDefecto:'Sesiones'
            }
        }
    },
    totalLibros: {
        id: 'total_libros',
        traduccion: 'libros',
        propiedad: 'librosLeidos', // propiedad para buscar en los objetos estadisticos.
        ejes:{
            y:{
                traduccion:'total_libros_leidos',
                textoPorDefecto:'Libros'
            }
        }
    },
    promedioSesiones: {
        id: 'promedio_sesiones',
        traduccion: 'promedio_sesion',
        propiedad: 'tiempoPromedioEnPlataforma', // propiedad para buscar en los objetos estadisticos.
        ejes:{
            y:{
                traduccion:'tiempo_promedio_sesion',
                textoPorDefecto:'Tiempo de sesión (minutos)'
            }
        }
    },
    promedioLectura: {
        id: 'promedio_lectura',
        traduccion: 'promedio_lectura',
        propiedad: 'tiempoPromedioDeLectura', // propiedad para buscar en los objetos estadisticos.
        ejes:{
            y:{
                traduccion:'tiempo_promedio_lectura',
                textoPorDefecto:'Tiempo de lectura (minutos)'
            }
        }
    }
}

/**
 * Con base en las props que sean pasadas a este componente, su función será transformar los datos estadísticos para que puedan ser renderizados en una gráfica de lineas
 */
export const Graficas = (props) => {
    const { textosSubSecciones: traducciones } = useObtenerSubsecciones('estadisticas');
    const { comparaciones, fechas } = props;
    const { comparativa } = traducciones || {};
    const { grafica } = comparativa || {};
    const { categoria } = grafica || {};
    const [tipoGrafica, setTipoGrafica] = useState(TIPO_GRAFICAS.totalSesiones);
    const [estadisticas, setEstadisticas] = useState([]);
    const [datosGrafica, setDatosGrafica] = useState([]);
    const [etiquetas, setEtiquetas] = useState([]);
    const state = useTrackedState();
    const rolUsuario = state?.planActivo?.tipoUsuario || 'null';
  


    useEffect(() => {
        if (fechas) {
            const fechasDias = FiltroFechas.fechasEntreRango('', fechas?.inicio, fechas?.fin).map(dia => dia?.fecha);
            setEtiquetas(fechasDias);
        }
    }, [fechas])





    const TransformarEstadisticas = {
        /**
         * Transforma los datos estadisticas para que la grafica los pueda leer correctamente.
         * @param {string} etiqueta - nombre de las estadisticas a mostrar en la grafica.
         * @param {string} color - color de las estadisticas.
         * @param {string} datosEstadisticos - arreglo con los datos que se quiere mostrar.
         */
        aDatosGrafica: (etiqueta, color, datosEstadisticos) => {
            return {
                color: color,
                etiquetaTitulo: etiqueta,
                valores: datosEstadisticos
            }
        },

        /**
         * devuelve un arreglo con las estadisticas de todos los dias dentro del rango de fechas, Si no existen días con estadisticas dentro del rango de fechas especificado en las props, concatena las estadisticas pasadas como parametros con los días faltantes con estadisticas en 0
         * De este modo existira estadisticas de todos los día dentro del rango de fechas
         * @param {Array<Estadisticas>} estadisticasEncontradas 
         */
        completarEstadisticas: (estadisticasEncontradas) => {
            const diasTotales = FiltroFechas.fechasEntreRango('', fechas?.inicio, fechas?.fin)
            const estadisticasCompletas = diasTotales.map((dia) => {
                const estadistica = estadisticasEncontradas.find(estadistica => estadistica?.fecha.includes(dia?.fecha));
                return {
                    fecha: dia?.fecha || "",
                    numeroDeSesiones: estadistica?.numeroDeSesiones || 0,
                    tiempoPromedioDeLectura: estadistica?.tiempoPromedioDeLectura || 0,
                    tiempoPromedioEnPlataforma: estadistica?.tiempoPromedioEnPlataforma || 0,
                    librosLeidos: estadistica?.librosLeidos || 0
                }
            })
            return estadisticasCompletas
        }

    }

    useEffect(() => {
        const actualizarDatosGrafica = () => {
            const datosEstadisticos = estadisticas.map((estadistica, i) => {
                const nombreSede = rolUsuario==='rector'? 
                (comparaciones.length > 0 ? comparaciones[i]?.sede?.nombre || 'N/A':state?.datosInstitucion?.nombre || 'N/A')
                :
                (comparaciones.length > 0 ? comparaciones[i]?.curso?.nombre || 'N/A':comparativa?.rol_profesor?.todo || 'Todos tus cursos')

                const nombreCurso = comparaciones[i]?.curso?.nombre || 'N/A'
                const datosGrafica = TransformarEstadisticas.aDatosGrafica(
                    `${nombreSede} ${rolUsuario === 'rector'?(comparaciones[i]?.curso?.id && comparaciones[i]?.curso?.id !== 'todo' ? `- ${nombreCurso}` : ''):''}`,
                    COMPARACION_COLORES_DISPONIBLES[i]?.fondo,
                    estadistica.map(dato => dato[tipoGrafica?.propiedad])
                )
                return datosGrafica;
            })
            setDatosGrafica(datosEstadisticos)
        }
        actualizarDatosGrafica();

    }, [estadisticas, tipoGrafica])

    useEffect(() => {
        const cargarEstadisticas = async () => {
            try {
                if (Array.isArray(comparaciones) && comparaciones.length > 0) {
                    Promise.all(comparaciones.map(async (comparacion) => {
                        if (Boolean(comparacion?.curso?.id) && comparacion?.curso?.id !== 'todo') {
                            return await DetallesSesionesAPI.obtenerPorCurso(comparacion?.curso?.id, fechas?.inicio, fechas?.fin);
                        }
                        else {
                            return await DetallesSesionesAPI.obtenerPorSede(comparacion?.sede?.id, fechas?.inicio, fechas?.fin);
                        }
                    }))
                        .then(resultados => {
                            const estadisticasCompletas = resultados.map(resultado => {
                                return TransformarEstadisticas.completarEstadisticas(resultado?.datos)
                            })
                            setEstadisticas(estadisticasCompletas)

                        })

                } else {
                    await DetallesSesionesAPI.obtenerPorSede(undefined, fechas?.inicio, fechas?.fin)
                        .then(resultado => {
                                const estadisticasCompletas= TransformarEstadisticas.completarEstadisticas(resultado?.datos)
                            setEstadisticas([estadisticasCompletas])
                        })
                }

            } catch (error) {
                console.log(error);
            }
        }

        cargarEstadisticas();

    }, [fechas, comparaciones])




    return (
        <div className="grid grid-cols-1 grid-rows-none lg:grid-cols-4">
            <div className="border-r onsurface-var2--border-color ">
                {
                    Object.values(TIPO_GRAFICAS).map((tipo,i) => {
                        return <TooltipGrafica
                            key={i}
                            descripcionTool={ categoria?.[`${tipo?.traduccion}`] ||  'N/A'}
                            opcionActiva={tipoGrafica?.id === tipo?.id}
                            alSeleccionar={() => setTipoGrafica(tipo)}
                        />
                    })
                }
            </div>
                <div className="p-3 m-2 lg:col-span-3">
                    <Grafica
                        titulo={categoria?.[`${tipoGrafica?.traduccion}`] ||  'N/A'}
                        limites={{
                            min: fechas?.inicio,
                            max: fechas?.fin
                        }}
                        ejes={{
                            x: { titulo: traducciones?.grafica?.ejes?.x || "Fechas", tipo: 'fechas' },
                            y: { titulo:  traducciones?.grafica?.[tipoGrafica?.ejes?.y?.traduccion]?.ejes?.y || tipoGrafica?.ejes?.y?.textoPorDefecto, tipo: 'numeros' },
                        }}
                        datos={
                            {
                                etiquetas: etiquetas,
                                datos: datosGrafica
                            }
                        }
                    />
                </div>
        </div>
    )
} 

Graficas.propTypes = {
    /**
     * Objeto con la fecha de inicio y fin de la consulta
     */
    fechas: PropTypes.shape({
        inicio: PropTypes.string,
        fin: PropTypes.string
    }),
        /**
     * Arreglo de objetos con los datos de los cursos o sedes que se están comparando.
     */
    comparaciones: PropTypes.arrayOf(PropTypes.shape({
        sede: PropTypes.shape({
            id: PropTypes.string,
            nombre: PropTypes.string
        }),
        curso: PropTypes.shape({
            id: PropTypes.string,
            nombre: PropTypes.string
        })
    })),
}