import axios from "axios";

export class DetalleProfesoresAPI {

 

  // DEFINICIÓN DE LOS TIPOS
  /**
   * @typedef {{
    username: string,// este es el correo
    fechaLectura: string,
    nombreCompleto: string,
    sesionesDeLectura: number,
    tiempoTotalLectura:number,
    tiempoPromedioDeLectura: number,//mins
    tiempoPromedioDeSesion: number, //mins
    instituto: string
  }} DetalleProfesores
**/

/**
 * @type {DetalleProfesores}
 */
static detalleEstudiantesPorDefecto = {
  username:'N/A',
  nombreCompleto:'N/A',
  sesionesDeLectura:0,
  tiempoPromedioDeLectura:0,
  tiempoPromedioDeSesion:0,
  fechaLectura:'N/A',
  instituto:'N/A',
  tiempoTotalLectura:0,
}



  //---------------------------------------------------------------------------------
  // EMPIEZAN LOS SERVICIOS

  /**
* devuelve un arreglo con las estadisticas de los profesores 
* @param {String} fechaInicio - la fecha de inicio de busqueda de información
* @param {String} fechaFin - la fecha de fin  de busqueda de información
* @returns {Promise<DetalleProfesores[]>} DetalleProfesores[]
*/
  static async obtenerTodos(fechaInicio, fechaFin) {
    const body = {
        fechaInicio:fechaInicio || '', 
        fechaFin:fechaFin || ''
    }
    try {
      axios.defaults.baseURL = process.env.REACT_APP_API_URL;
      axios.defaults.withCredentials = true;
      const response = await axios({
        method: 'POST',
        url: 'estadisticas/estadisticasDetalleProfesores',
        data:body
      })
      if (response.data.status != 1) {
        return new Error(response.data?.data?.info || 'Hubo un error, intente más tarde.');
    }

    const estadisticas = response.data?.data || [DetalleProfesoresAPI.EstadisticaGeneralPorDefecto];
    if(estadisticas && Array.isArray(estadisticas)){
        return estadisticas.map((estadistica,i)=>{
            const objeto=estadistica[Object.keys(estadistica)[0]];
            return {...objeto, idSede:Object.keys(estadistica)[0]}
        })
    }
    return [];
      
    } catch (error) {
      return Promise.reject(error);
    }

  }

}

