import axios from "axios";

export class EstadisticasGeneralesAPI {



    // DEFINICIÓN DE LOS TIPOS
    /**
     * @typedef {  
                  {
                  "idCurso":string | null, // opcional depende del servicio usado
                  "idSede":string | null, // opcional depende del servicio usado
                  "totalSesionesPlataforma": number,
                  "tiempoPromedioPorSesion": number,
                  "tiempoPromedioDeLectura": number,
                  "promedioDeLibrosPorSesion": number,
                  "totalLibrosLeidos": number,
                  "librosMasLeidos": [
                      {
                        nombreLibro: string,
                        numeroDeLecturas: number
                      }
  
                  ],
                  "detalleSesionesLectura": [
                        {
                        numeroDeLibros: number
                        numeroDeSesiones: number
                        sessionDate: 'yyy-mm-dd',
                        tiempoPromedioLectura: number //mins
                        tiempoPromedioPlataforma: number //mins
                        }

                  ],
                  "ultimosIngresos": [
                    {
                        nombreCompletoEstudiante: string,
                        fechaIngreso: 'yyyy-mm-dd-hh',
                        librosLeidos: number,
                        nombreProfesor: string[],
                        tiempoPromedio: number //mins
                    }

                  ]
              }
  } EstadisticaGeneral
  **/

    /**
     * @type {EstadisticaGeneral}
     */
    static EstadisticaGeneralPorDefecto = {
        idCurso: 'N/A',
        idSede: 'N/A',
        detalleSesiones: [],
        librosMasLeidos: [],
        ultimosIngresos: [],
        promedioDeLibrosPorSesion: 0,
        tiempoPromedioDeLectura: 0,
        tiempoPromedioPorSesion: 0,
        totalSesionesPlataforma: 0,
    }



    //---------------------------------------------------------------------------------
    // EMPIEZAN LOS SERVICIOS

    /**
  * devuelve un arreglo con las estadisticas de los cursos que se especifican en los parametros.
  * @param {String[]} idCursos - arreglo de id de los cursos
  * @param {String} fechaInicio - la fecha de inicio de busqueda de información
  * @param {String} fechaFin - la fecha de fin  de busqueda de información
  * @returns {Promise<EstadisticaGeneral[]>} EstadisticaGeneral[]
  */
    static async comparacionPorCursos(idCursos, fechaInicio, fechaFin) {
        const body = {
            cursos: idCursos || [],
            fechaInicio: fechaInicio || '',
            fechaFin: fechaFin || ''
        }
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method: 'POST',
                url: 'estadisticas/estadisticasGeneral',
                data: body
            })
            if (response.data.status != 1) {
                return new Error(response.data?.data?.info || 'Hubo un error, intente más tarde.');
            }


            const estadisticas = response.data?.data || [EstadisticasGeneralesAPI.EstadisticaGeneralPorDefecto];
            if (estadisticas && Array.isArray(estadisticas)) {
                return estadisticas.map((estadisticaCurso, i) => {
                    const objeto = estadisticaCurso[Object.keys(estadisticaCurso)[0]];
                    return { ...objeto, idCurso: Object.keys(estadisticaCurso)[0] }
                })
            }
            return [];

        } catch (error) {
            return Promise.reject(error);
        }

    }

    /**
  * devuelve un objeto con las estadisticas de las sedes que se especifican en los parametros.
  * @param {String[]} idSedes - arreglo de IDs de las sedes
  * @param {String} fechaInicio - la fecha de inicio de busqueda de información
  * @param {String} fechaFin - la fecha de fin  de busqueda de información
  * @returns {Promise<EstadisticaGeneral[]>} []
  */
    static async comparacionPorSedes(idSedes, fechaInicio, fechaFin) {
        const body = {
            sedes: idSedes || [],
            fechaInicio: fechaInicio || '',
            fechaFin: fechaFin || ''
        }
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method: 'POST',
                url: 'estadisticas/estadisticasGeneral',
                data: body
            })


            if (response.data.status != 1) {
                return new Error(response.data?.data?.info || 'Hubo un error, intente más tarde.');
            }

            const estadisticas = response.data?.data || [EstadisticasGeneralesAPI.EstadisticaGeneralPorDefecto];
            if (estadisticas && Array.isArray(estadisticas)) {
                return estadisticas.map((estadisticaSede, i) => {
                    const objeto = estadisticaSede[Object.keys(estadisticaSede)[0]];
                    return { ...objeto, idSede: Object.keys(estadisticaSede)[0] }
                })
            }
            return [];

        } catch (error) {
            return Promise.reject(error);
        }

    }

    /**
* Devuelve un objeto con todas las estadísticas de la institución.
* @param {String} fechaInicio - la fecha de inicio de busqueda de información
* @param {String} fechaFin - la fecha de fin  de busqueda de información
* @returns {Promise<EstadisticaGeneral>} 
*/
    static async estadisticasInstitucion(fechaInicio, fechaFin) {
        const body = {
            fechaInicio: fechaInicio || '',
            fechaFin: fechaFin || ''
        }
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method: 'POST',
                url: 'estadisticas/estadisticasGeneral',
                data: body
            })
            if (response.data.status != 1) {
                return new Error(response.data?.data?.info || 'Hubo un error, intente más tarde.');
            }

            const estadisticas = response.data?.data || EstadisticasGeneralesAPI.EstadisticaGeneralPorDefecto;
            return estadisticas;

        } catch (error) {
            return Promise.reject(error);
        }

    }
}

