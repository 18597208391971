import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { LibrosAPI } from "../../api/LibrosAPI";
import LibroFicha from "../../components/libros/ficha/LibroFicha"
import SkeletonFichaLibro from "../../components/libros/Skeletons/SkeletonFichaLibro";

import { adaptarPortadaLibros } from "../../utilities/adaptadores";

import useObtenerSubsecciones from "../helpers/hooks/useObtenerSubsecciones";
import PropTypes from 'prop-types';

/**
 * Este container tiene la función principal de cargar la información de libro, cargar los textos traducidos y renderizar esta información en el componente 
  'LibroFicha'.
 * @returns   {JSX.Element} LibroFicha
 */
const FichaLibro = (props) => {
  const { informacionLibro, escogiendoLibrosProps } = props;
  const [libro, setLibro] = useState(undefined);
  const parametros = useParams();
  const history = useHistory();

  console.log('test',informacionLibro)

  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('libros');

  useEffect(()=>{
    const cargarInfoLibro = async ()=>{
      if(informacionLibro){
        setLibro(informacionLibro);
      }else{
        await LibrosAPI.todosBuscarPorId(parametros?.idLibro)
        .then(resultado=>{
          if(resultado){
            setLibro(adaptarPortadaLibros([resultado])[0]);
          }else{
            history.push('/libros');
          }
        })
        .catch(error=>console.log(error))
      }
    
    }
    cargarInfoLibro();
  },[parametros?.idLibro, informacionLibro])
  


  return (
    <>
      {
        isReady && libro ?
        <LibroFicha
          {...props}
          informacionLibro = {libro}
          escogiendoLibrosProps={escogiendoLibrosProps}
          textosInterfaz={textosInterfaz}
        />
        :<SkeletonFichaLibro/>
      }

    </>
  )
}

export default FichaLibro

FichaLibro.propTypes = { 
  /**
   * Objeto con todas las propiedades del libro que se quiere mostrar.
   */ 
  informacionLibro: PropTypes.object, 
  /**
   *  Objeto que se pasa si el usuario esta en el modo de escoger libros, las propiedades son el paquete actual y los paquetes disponibles.
   */
  escogiendoLibrosProps: PropTypes.shape({
      paqueteActual: PropTypes.object,
      paquetesDisponibles: PropTypes.array
  }), 
}