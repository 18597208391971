import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

//componentes
import { MigasDePan, Miga } from "../../globales/MigaDePan";
import TituloInstituto from "../../globales/estadisticas/TituloInstituto";
import TarjetaLibrosLeidos from "../../globales/estadisticas/TarjetaLibrosLeidos";
import TituloSeccion from "../../globales/estadisticas/TituloSeccion";
import moment from 'moment';
import { useTrackedState } from "../../../store";
import { FiltroFechas } from "../../../utilities/gestion_fechas/FiltrosFechas";
import { EstadisticasGeneralesAPI } from "../../../api/estadisticas/EstadisticasGeneralesAPI";
import { useFechaPorDefecto } from "../hooks/Fechas";
import { useQueryParams } from "../../../containers/helpers/hooks/locationHooks";
import { EstadisticasComparativas } from ".";
import { CargandoInformacion } from "../../globales/estadisticas/CargandoInformacion";
import { COMPARACION_COLORES_DISPONIBLES } from "../../../containers/estadisticas/Constantes";
import { CursosAPI } from "../../../api/CursosAPI";
import { SedesAPI } from "../../../api/SedesAPI";
import ReactTooltip from "react-tooltip";
import useIsMobile from "../../../containers/helpers/hooks/useIsMobile";
import PropTypes from 'prop-types';
import { AccesibilidadContext } from '../../../contexts/AccesibilidadContext';

/**
 * Este componente se encarga de realizar la lógica y mostrar las estadísticas generales de los estudiantes dependiendo de las sede y curso que se escoja, su característica principal es la comparación estadística de 2 cursos o sedes dependiendo del rol del usuario, las estadísticas se actualizan automaticamente al momento de realizar cambios en los filtros (fecha, sede, curso y/o agregar reporte de comparación), estos filtros mencionados también pueden ser pasados por QueryParams por lo tanto el componente actualizara las estadísticas si se pasan estos parametros en la url.
 */
export const ComparativoComponent = (props) => {
  const { traducciones } = props;
  const { comparativa, miga_de_pan, botones, filtro } = traducciones;
  const { top_libros, grafica } = comparativa;

  const limiteComparacion = 2;

  const state = useTrackedState();
  const rolUsuario = state?.planActivo?.tipoUsuario || 'null';

  const history = useHistory();
  const queryParams = useQueryParams();
  const fechaPorDefecto = useFechaPorDefecto();
  const isMobile = useIsMobile();
  const accesibilidad = useContext(AccesibilidadContext);


  const [elementosAcomparar, setElementosAcomparar] = useState([]);
  const [filtros, setFiltros] = useState({
    sede: undefined,
    curso: undefined,
    fechas: {
      inicio: fechaPorDefecto.inicio,
      fin: fechaPorDefecto.fin
    }
  })
  const [fechaUsuario, setFechaUsuario] = useState({
    inicio: '',
    fin: '',
  });
  const [estadisticasMultiples, setEstadisticasMultiples] = useState([]);

  const [cargando, setCargando] = useState({
    estadisticas: false
  })


  const removerItemComparacion = (item) => {
    const nuevaComparacion = elementosAcomparar.filter(itemAgregado => itemAgregado !== item);
    setElementosAcomparar(nuevaComparacion);
  }

  const alAgregarReporte = (filtros) => {
    const nuevoReporte = {
      sede: filtros?.sede,
      curso: filtros?.curso
    }

    setElementosAcomparar([...elementosAcomparar, nuevoReporte])

  }

  const alActualizarFiltros = (filtrosActualizados) => {
    setFiltros({
      fechas: {
        inicio: filtrosActualizados?.fechas[0] || '',
        fin: filtrosActualizados?.fechas[1] || ''
      },
      sede: filtrosActualizados?.sede,
      curso: filtrosActualizados?.curso,
    })
  }


  useEffect(() => {
    setFechaUsuario({
      inicio: FiltroFechas.formatoUsuario(state?.idioma, filtros?.fechas?.inicio),
      fin: FiltroFechas.formatoUsuario(state?.idioma, filtros?.fechas?.fin),
    });
  }, [filtros?.fechas, state?.idioma])

  useEffect(() => {
    /** esta función es esencial, actualiza el arreglo de estadisticas
      con la información pertinente en base a fechas y sedes o cursos que 
      se esten comparando, si no se esta comparando se devuelve un solo elemento 
      en el arreglo de las estadisticas y cuya información es de toda la institución */
    const actualizarEstadisticas = async () => {
      setCargando({
        ...cargando,
        estadisticas: true
      })
      await EstadisticasGeneralesAPI.estadisticasInstitucion(filtros?.fechas?.inicio, filtros?.fechas?.fin)
        .then(resultado => {
          const estadisticas = {
            ...resultado,
            nombreSede: state?.datosInstitucion?.nombre || 'N/A',
          }
          if (elementosAcomparar.length === 0) {
            setEstadisticasMultiples([estadisticas])
          }
        })
        .catch(error => console.log(error));

      if (elementosAcomparar.length > 0) {
        const estadisticasAux = [];
        await Promise.all(
          elementosAcomparar.map(async item => {
            if (item?.curso?.id === 'todo') {
              if(rolUsuario === 'rector'){
                return await EstadisticasGeneralesAPI.comparacionPorSedes([item?.sede?.id], filtros?.fechas?.inicio, filtros?.fechas?.fin)
                  .then(resultado => {
                    if (resultado.length > 0) {
                      estadisticasAux.push({
                        ...resultado[0],
                      })
                    }
  
                  })
              }else{
                return await EstadisticasGeneralesAPI.estadisticasInstitucion(filtros?.fechas?.inicio, filtros?.fechas?.fin)
                .then(resultado => {
                  const estadisticas = {
                    ...resultado,
                    nombreSede: state?.datosInstitucion?.nombre || 'N/A',
                  }
                  estadisticasAux.push({
                    ...estadisticas,
                  })
                })
                .catch(error => console.log(error));
              }
            }
            else {
              return await EstadisticasGeneralesAPI.comparacionPorCursos([item?.curso?.id], filtros?.fechas?.inicio, filtros?.fechas?.fin)
                .then(resultado => {
                  if (resultado.length > 0) {
                    estadisticasAux.push({
                      ...resultado[0],
                    })
                  }

                })
            }
          })
        )
        setEstadisticasMultiples(estadisticasAux)
      }
      setCargando({
        ...cargando,
        estadisticas: false
      })
    }
    if (filtros?.fechas?.inicio && filtros?.fechas?.fin) {
      actualizarEstadisticas();
    }
  }, [elementosAcomparar, filtros?.fechas?.inicio, filtros?.fechas?.fin, state?.idioma])


  useEffect(() => {
    const cargarReportes = async () => {
      const sede = queryParams.get('sede');
      const curso = queryParams.get('curso');
      if ((sede && Boolean(curso) === false) || curso === 'todo') {
        await SedesAPI.obtenerTodas()
          .then(resultado => {
            const sedeEncontrada = resultado?.find(c => c?.id === sede);
            setElementosAcomparar([
              ...elementosAcomparar,
              {
                sede: {
                  id: sede,
                  nombre: sedeEncontrada?.nombre
                },
                curso:
                {
                  id: 'todo',
                  nombre: 'Todos los cursos'
                }

              }
            ])
          })
          .catch(error => console.log(error))
      }
      if ((sede && curso) || curso) {
        await CursosAPI.obtenerTodos()
          .then(resultado => {
            const cursoEncontrado = resultado?.find(c => c?.id === curso);
            setElementosAcomparar([
              ...elementosAcomparar,
              {
                sede: {
                  id: sede,
                  nombre: cursoEncontrado?.sedeNombre
                },
                curso:
                {
                  id: curso,
                  nombre: cursoEncontrado?.nombre
                }

              }
            ])
          })
          .catch(error => console.log(error))
      }

    }
    cargarReportes();
  }, [queryParams])





  return (
    <>
      <MigasDePan>
        <Miga
          texto={miga_de_pan?.estadisticas || 'Estadisticas'}
          alSeleccionar={() => {
            history.push('/estadisticas')
          }} />
        <Miga texto={miga_de_pan?.comparativa || 'Reportes gráficos de estudiantes'} esRutaActual={true} />
      </MigasDePan>

      <div className="contenido">

        <h1 className="titulos-principales">{comparativa.titulo}</h1>
        <p className="informacion-principal">{comparativa.descripcion} <br/> <span className="italic primary-inv-var2--color">{comparativa?.nota || 'Nota: estos reportes no incluyen las estadísticas de los profesores.'}</span></p>

        <EstadisticasComparativas.Filtro
          alAgregarReporte={alAgregarReporte}
          alActualizarFiltros={alActualizarFiltros}
          comparaciones={elementosAcomparar}
          limiteComparaciones={limiteComparacion}
          calendarioProps={{
            fechaMaxima: moment().format('YYYY-MM-DD'),
            fechaMinima: state?.datosInstitucion?.fechaInicioSuscripcion || '',
            modoRango: true,
            fechaPorDefecto: [
              state?.estadisticas?.filtros?.fechas?.inicio || queryParams.get('fechaInicio') || fechaPorDefecto?.inicio, 
              state?.estadisticas?.filtros?.fechas?.fin || queryParams.get('fechaFin') || fechaPorDefecto.fin
            ],
            zIndex: 9999,
            modoMultiple: false,
            placeholder: ''
          }}
          sedePorDefecto={
            queryParams.get('sede') || 
            state?.estadisticas?.filtros?.sede  
           }
         cursoPorDefecto={   
           queryParams.get('curso') || 
           state?.estadisticas?.filtros?.curso 
         }
        />




        <>

          <h2 className=" r4-16-negro4A mt-5">{filtro.estas_viendo}:</h2>

          <div className="sm:flex">
            {
              elementosAcomparar.length <= 0 ?
                <div className="w-full">
                  <TituloInstituto
                    colorFondo={COMPARACION_COLORES_DISPONIBLES[0].fondo}
                    colorTexto={COMPARACION_COLORES_DISPONIBLES[0].texto}
                    nombreColegio={rolUsuario === 'rector' ? state?.datosInstitucion?.nombre || 'N/A' : comparativa?.rol_profesor?.todo || 'Todos tus cursos'}
                    nombreCurso={''}
                    elementosVisibles={{
                      botonCerrar: false
                    }}
                  />
                </div>
                : <>
                  {
                    elementosAcomparar.map((item, i) => {
                      const color = COMPARACION_COLORES_DISPONIBLES[i]
                      return (
                        <div key={i} className="mx-3 sm:w-6/12 xl:w-auto">
                          <TituloInstituto
                            colorFondo={color.fondo}
                            colorTexto={color.texto}
                            nombreColegio={rolUsuario === 'rector' ? item.sede?.nombre : item.curso?.nombre}
                            nombreCurso={rolUsuario === 'rector' ? ((item?.curso?.id !== 'todo') ? item.curso?.nombre : '') : null}
                            alCerrar={() => removerItemComparacion(item)}
                            elementosVisibles={{
                              botonCerrar: true
                            }}
                          />
                        </div>
                      )
                    })
                  }
                </>
            }
          </div>

        </>
        <hr className="my-8" aria-hidden={true} />

        {Boolean(cargando?.estadisticas) === true ?
          <CargandoInformacion />
          : <>
            {/* GRAFICAS ------------------------------------ */}
            <div className={`shadow-2 background-var3--bg rounded-lg ${accesibilidad.temaActual !== "clasico" && "onprimary-var1--bordered"}`}>
              <div className="p-3 sm:alineado-verticalmente border-b onsurface-var2--border-color">
                <div className="sm:w-6/12">
                  <h2
                    className="q7-20 onprimary-var2--color"
                    data-tip={true}
                    data-scroll-hide={true}
                    data-for='tool-descripcion'
                  >
                    {grafica.titulo}
                    <span 
                      className={`" mx-2 w-7 h-7 text-24 surface-var2--bg onprimary-var1--color cursor-pointer center inline-flex  rounded-full " ${accesibilidad.temaActual === "clasico" ? 'surface-var2--bordered ' : 'onprimary-var1--bordered'}`}
                      aria-label={traducciones?.grafica?.ayuda?.pc?.uso ||
                        `Dentro de la grafica mantenga presionado click izquierdo para moverse horizontalmente.Para ampliar o reducir la grafica, utilice la rueda del mouse. Si desea restaurar el estado de la grafica, oprima el botón 'Restaurar grafica'.`}
                    >?</span>

                  </h2>
                  <ReactTooltip
                    backgroundColor="#000"
                    className={`quicksand-400 ${accesibilidad.temaActual === "clasico" ? 'border-none' : 'onprimary-var1--bordered'}`}
                    id="tool-descripcion"
                    textColor={accesibilidad.temaActual === "clasico" ? '#FFFFFF' : '#ffff00'}
                    place={isMobile ? "bottom" : "right"}
                    event="mouseover mouseenter click"
                    eventOff="mouseleave mouseout scroll mousewheel blur"
                    effect={isMobile ? "solid" : "float"}
                  >
                    <p className="w-72">
                      <span className="font-bold">
                        {traducciones?.grafica?.ayuda?.pc?.titulo ||
                          `Escritorio`
                        }:
                      </span>
                      {traducciones?.grafica?.ayuda?.pc?.uso ||
                        `Dentro de la grafica mantenga presionado click izquierdo para moverse horizontalmente.Para ampliar o reducir la grafica, utilice la rueda del mouse. Si desea restaurar el estado de la grafica, oprima el botón 'Restaurar grafica'.`
                      }
                      <br />
                      <br />
                      <span className="font-bold">
                        {traducciones?.grafica?.ayuda?.movil?.titulo ||
                          `Dispositivos moviles`
                        }:
                      </span>
                      {traducciones?.grafica?.ayuda?.movil?.uso ||
                        `Utilice uno de sus dedos para moverse horizontalmente en la grafica .Para ampliar o reducir la grafica, utilice 2 dedos como si fuera una imagen. Si desea restaurar el estado de la grafica, oprima el botón 'Restaurar grafica'.`
                      }
                    </p>
                  </ReactTooltip>

                </div>
                <div className="sm:w-6/12">
                  <p className="r7-20-rojo sm:alineado-izquierda-center">{fechaUsuario.inicio} - {fechaUsuario.fin}</p>
                </div>
              </div>
              <div>

                <EstadisticasComparativas.Grafica
                  comparaciones={elementosAcomparar}
                  fechas={filtros?.fechas}
                />

                <EstadisticasComparativas.TablaComparativa
                  estadisticas={estadisticasMultiples}
                  comparaciones={elementosAcomparar}
                />

              </div>
            </div>

            <hr className="my-8" aria-hidden={true}/>



            {/* libros mas leidos ------------------------------------ */}
            <TituloSeccion
              titulo={top_libros.titulo}
              fecha={`${fechaUsuario.inicio || 'N/A'} - ${fechaUsuario.fin || 'N/A'}`}
            />

            <div className="md:grid grid-cols-2 gap-4">
              {estadisticasMultiples.map((estadistica, i) => {
                return <div key={i}>
                  <TituloInstituto
                    colorFondo={COMPARACION_COLORES_DISPONIBLES[i].fondo}
                    colorTexto={COMPARACION_COLORES_DISPONIBLES[i].texto}
                    nombreCurso={
                      rolUsuario === 'rector' ?
                        ((elementosAcomparar.length > 0 && elementosAcomparar[i]?.curso?.id !== 'todo') ? elementosAcomparar[i]?.curso?.nombre || 'N/A' : '')
                        :
                        null
                    }
                    nombreColegio={
                      rolUsuario === 'rector' ?
                        (elementosAcomparar.length > 0 ? elementosAcomparar[i]?.sede?.nombre || 'N/A' : estadistica?.nombreSede || 'N/A')
                        :
                        (elementosAcomparar.length > 0 ? elementosAcomparar[i]?.curso?.nombre || 'N/A' : comparativa?.rol_profesor?.todo || 'Todos tus cursos')
                    }
                  />
                  <TarjetaLibrosLeidos
                    colorTarjeta={COMPARACION_COLORES_DISPONIBLES[i]?.fondo}
                    librosLeidos={estadistica?.librosMasLeidos}
                    totalLibros={estadistica?.totalLibrosLeidos || 0}
                    textoTarjeta={{
                      totalLibros: top_libros.total_libros,
                      libroPlural: top_libros.libro.plural,
                      libroSingular: top_libros.libro.singular,
                      libros: top_libros.tabla.libro,
                      lecturas: top_libros.tabla.lecturas,
                      botonVertodos: botones.todos
                    }}
                    alVerTodos={() => history.push({
                      pathname: `/estadisticas/estudiantes/libros-leidos`,
                      search: `?fechaInicio=${filtros?.fechas?.inicio}&fechaFin=${filtros?.fechas?.fin}&sede=${elementosAcomparar.length > 0 ? elementosAcomparar[i]?.sede?.id : 'todo'}&curso=${elementosAcomparar.length > 0 ? elementosAcomparar[i]?.curso?.id : 'todo'}`
                    })}
                  />
                </div>

              })}

            </div>
          </>

        }


      </div>

    </>
  )
}

ComparativoComponent.propTypes = {
  /**
   * Objeto con los textos que se deben renderizar en la sección
   */
  traducciones: PropTypes.object.isRequired,
}