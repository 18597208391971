import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import normalizeString from "../../utilities/function/normalizeString";

/**componentes */ import NoHayResultados from "../globales/NoHayResultados";
import { BotonVolverArriba } from "../globales/BotonVolverArriba";
import SkeletonPortadasLibros from "./Skeletons/SkeletonPortadasLibros";
import { Libro } from "../globales/libro/LibroComponent";
import { useHistory } from "react-router-dom";
import { AccionesLibroAPI } from "../../api/AccionesLibroAPI";
import { Icon } from "@iconify/react";
import useObtenerSubsecciones from "../../containers/helpers/hooks/useObtenerSubsecciones";
import PropTypes from "prop-types";

/**
 * Componente que se encarga de renderizar un listado de libros de manera progresiva, esto con el fin de mejorar el rendimiento de la aplicación.
 */
const ListadoLibros = (props) => {
	const {
		libros,
		schFilter,
		isLoadingLibros,
		itemsActivos,
		selectFunctions,
		multiselectState,
		eligiendoLibros,
		textosInterfaz,
		contenedorRef,
		// refrescarLibros,
		permitirMultiSeleccion,
	} = props;

	const { sin_resultados } = textosInterfaz;
	const { selectLibroMultiselect } = selectFunctions;
	const [librosAMostrar, setLibrosAMostrar] = useState([]);
	const cantidadLibrosAMostrar = 21;
	const [librosCargados, setLibrosCargados] = useState(false);
	const history = useHistory();
	const { textosSubSecciones: traducciones } = useObtenerSubsecciones("libros");

	const agregarRemoverAFavoritos = async (libro, favorito) => {
		await AccionesLibroAPI.agregarRemoverAFavoritos([libro?.idLibro], favorito).catch((error) =>
			console.log(error)
		);
	};

	useEffect(() => {
		// Carga los 10 primeros libros
		const cargaInicial = () => {
			if (Array.isArray(libros) && libros.length > 0) {
				if (
					librosAMostrar.length <= cantidadLibrosAMostrar ||
					libros.length <= cantidadLibrosAMostrar
				) {
					setLibrosAMostrar(libros.slice(0, cantidadLibrosAMostrar));
				} else {
					setLibrosAMostrar(libros.slice(0, librosAMostrar.length));
				}
				libros.length <= cantidadLibrosAMostrar && setLibrosCargados(true);
			}
		};

		setLibrosCargados(false);
		cargaInicial();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [libros]);

	useEffect(() => {
		if (librosAMostrar.length >= libros.length) {
			setLibrosCargados(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [librosAMostrar]);

	/**Funciones */
	const libroVisibility = (libro) => {
		if (eligiendoLibros) {
			return Boolean(libro?.elegido);
		} else {
			return libro?.cursos?.includes(itemsActivos.curso);
		}
	};

	// Funcion que agrega 10 libros a los libros que se estan mostrando en pantalla
	const cargaProgresiva = async () => {
		let timer = null;
		return await new Promise((resolve, reject) => {
			if (timer) {
				clearTimeout(timer);
			}
			timer = setTimeout(() => {
				let contador = 0;
				const nuevosLibros = [];
				for (let i = librosAMostrar.length; i < libros.length; i++) {
					const libro = libros[i];
					if (contador < cantidadLibrosAMostrar) {
						nuevosLibros.push(libro);
					}
					contador++;
				}
				setLibrosAMostrar([...librosAMostrar, ...nuevosLibros]);
				if (librosAMostrar.length === libros.length) {
					setLibrosCargados(true);
				}
				resolve(true);
			}, 1000);
		});
	};

	const manejarSeleccionCheckbox = (libro, estaSeleccionado) => {
		selectLibroMultiselect({ libro });
	};

	useEffect(() => {
		if (multiselectState?.idLibros?.length <= 0) {
			selectFunctions?.desactivateMultiselect && selectFunctions?.desactivateMultiselect();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [multiselectState]);

	return (
		<>
			{isLoadingLibros && (
				/* <SkeletonPortadasLibros /> */
				<div className="center pt-5">
					<span className="ml-10 icon-spinner9 animate-spin text-40 primary-inv-var2--color"></span>
				</div>
			)}
			{!isLoadingLibros && (
				<>
					{Boolean(libros) ? (
						libros.length <= 0 ? (
							<div className="w-10/12 mx-auto">
								<NoHayResultados
									descripcion={
										!!schFilter
											? `${sin_resultados.no_libro} "${schFilter}"`
											: sin_resultados.no_resultados
									}
									titulo={sin_resultados.titulo}
								/>
							</div>
						) : (
							<p className="titulos-principales text-22">
								{textosInterfaz.libros.total_resultados.hay_un_total}{" "}
								{libros.length} {textosInterfaz.libros.total_resultados.resultados}
							</p>
						)
					) : (
						<SkeletonPortadasLibros />
					)}
					<div className="w-full" ref={contenedorRef}>
						{libros && libros.length > 0 && (
							<InfiniteScroll
								style={{ overflow: "visible" }}
								className="w-full min-h-full  grid grid-cols-2  md:grid-cols-3 xl:grid-cols-4  mt-5"
								dataLength={librosAMostrar.length}
								next={cargaProgresiva}
								hasMore={librosCargados ? false : true}
								scrollThreshold={0.86}
								loader={
									<div className="w-full center pt-5 col-span-full">
										<span className="ml-10 icon-spinner9 animate-spin text-40 primary-inv-var2--color"></span>
									</div>
								}
								// endMessage={<h1>Ya has visto todo</h1>}
							>
								{librosAMostrar?.map((libro, i) => {
									return (
										<div className="mb-5">
											<Libro
												key={`libro-${i}`}
												libro={libro}
												clasesPersonalizadas={{
													contenedor: "min-w-full p-4",
												}}
												alSeleccionar={() =>
													history.push(
														`/fichas/${
															libro?.idLibro
														}/${normalizeString(libro?.title)}`
													)
												}
												alAgregarFav={(libro) =>
													agregarRemoverAFavoritos(libro, true)
												}
												alRemoverFav={(libro) =>
													agregarRemoverAFavoritos(libro, false)
												}
												alCambiarEstadoCheckbox={(
													libro,
													estaSeleccionado
												) => {
													manejarSeleccionCheckbox(
														libro,
														estaSeleccionado
													);
												}}
												estadoInicial={{
													esCheckboxMarcado:
														multiselectState?.idLibros.includes(
															libro.idLibro
														),
													esFavorito: libro?.favorito,
												}}
												ocultarOpciones={{
													botonFavoritos: eligiendoLibros ? true : false,
													botonMultiSeleccion: !permitirMultiSeleccion,
												}}
												esVisible={libroVisibility(libro)}
												elementosAdicionales={{
													portada: (
														<div className="flex w-full h-full pointer-events-none">
															<div className="w-full flex flex-col place-self-end pointer-events-none">
																{Boolean(eligiendoLibros) ===
																	false &&
																	libroVisibility(libro) && (
																		<h2 className="w-full text-12 font-raleway flex flex-row center p-1 primary-inv-var2--bg onprimary-inv-var1--color text-center">
																			{traducciones?.libros
																				?.sesion_activa
																				?.asignado ||
																				"Asignado"}
																			<Icon
																				icon={
																					"akar-icons:check"
																				}
																				className={
																					"m-1 text-12"
																				}
																			/>
																		</h2>
																	)}
																{Boolean(eligiendoLibros) &&
																	Boolean(libro?.elegido) && (
																		<h2 className="w-full text-12 font-raleway flex flex-row center p-1 primary-inv-var2--bg onprimary-inv-var1--color text-center">
																			{traducciones?.libros
																				?.elegir_libros
																				?.seleccionado ||
																				"Seleccionado"}
																			<Icon
																				icon={
																					"akar-icons:check"
																				}
																				className={
																					"m-1 text-12"
																				}
																			/>
																		</h2>
																	)}
															</div>
														</div>
													),
												}}
											/>
										</div>
									);
								})}
							</InfiniteScroll>
						)}

						{librosCargados == false && (
							<div className="flex flex-col flex-wrap center my-2 primary-inv-var2--color">
								<h1 className="primary-inv-var2--color font-bold font-raleway">
									{`${
										traducciones?.libros?.scroll?.informacion_carga ||
										" Desplazate hacia abajo para cargar más libros."
									}`}
								</h1>
								<Icon
									icon="mdi:mouse-move-down"
									className="primary-inv-var2--color text-28 mx-2"
								/>
							</div>
						)}
					</div>
				</>
			)}
			{librosAMostrar.length >= cantidadLibrosAMostrar && <BotonVolverArriba />}
		</>
	);
};

export default ListadoLibros;

ListadoLibros.propTypes = {
	/**
	 * arreglo de los libros que se desea renderizar
	 */
	libros: PropTypes.arrayOf(PropTypes.object),
	/**
	 * valor actual del filtro de busqueda por titulo.
	 */
	schFilter: PropTypes.string,
	/**
	 * indica si se esta cargando los libros o no
	 */
	isLoadingLibros: PropTypes.bool,
	/**
	 * objeto con los ids de el curso y sede actualmente seleccionados
	 */
	itemsActivos: PropTypes.shape({
		curso: PropTypes.string,
		sede: PropTypes.string,
	}),
	/**
	 * objeto con funciones para manipular la multiselección de libros.
	 */
	selectFunctions: PropTypes.object,
	/**
	 * Indica el estado de la seleccion de multiples libros
	 */
	multiselectState: PropTypes.object,
	/**
	 * Indica si se esta en modo elegir libros o no.
	 */
	eligiendoLibros: PropTypes.bool,
	/**
	 * Objeto con los textos de la interfaz del componente.
	 */
	textosInterfaz: PropTypes.object,
	/**
	 * elemento de referencia para ubicar el scroll en el top al momento de retornar al inicio de la lista al hacer click en el botón de volver arriba.
	 */
	contenedorRef: PropTypes.element,
	/**
	 * indica si se habilita o no la opción de seleccionar varios libros
	 */
	permitirMultiSeleccion: PropTypes.bool,
};
